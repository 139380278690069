import { arrowForward as arrowForwardIcon } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';

import ContentColumn from '../components/ContentColumn';
import '../css/pages/Boris.css';
import Page from './Page';

const Boris: React.FC = () => {
  return (
    <Page title="Broadcast">
      <div className="boris__video__container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/IL_0WwMPAsc?rel=0"
          title="Video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <ContentColumn>
        <div className="boris__buttons">
          <IonButton fill="clear" routerLink="/enlist">
            <IonIcon icon={arrowForwardIcon} slot="start" />
            Find out more
          </IonButton>
        </div>
      </ContentColumn>
    </Page>
  );
};

export default Boris;
