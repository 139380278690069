import { Browser } from '@capacitor/browser';
import { NewsItem } from 'climate-call-up-typescript-interfaces';
import dayJS from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import firebase from 'firebase';
import {
  add as addIcon,
  arrowUp as arrowUpIcon,
  arrowDown as arrowDownIcon,
  checkmark as checkmarkIcon,
  informationCircleOutline as informationCircleOutlineIcon,
  openOutline as openOutlineIcon,
  trash as trashIcon,
} from 'ionicons/icons';
import {
  IonButton,
  IonCard,
  IonDatetime,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonLoading,
  IonSpinner,
  IonToast,
  useIonAlert,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';

import { AuthContext, canEdit } from '../auth';
import ContentColumn from '../components/ContentColumn';
import Modal from '../components/Modal';
import '../css/pages/Alerts.css';
import { blankNewsItem } from '../data/alerts';
import { analytics, firestore } from '../firebase';
import siren from '../images/siren-short-800.png';
import { CO2Levels } from '../interfaces/CO2Levels';
import { CO2LevelsLimits } from '../interfaces/CO2LevelsLimits';
import Page from './Page';

dayJS.extend(customParseFormat);

interface Props {
  co2Levels: CO2Levels | null;
  co2LevelSource: { infoUrl: string; name: string };
  co2LevelsLimits: CO2LevelsLimits;
  loading: boolean;
  news: NewsItem[] | null;
  updateNewsItem: (newsItem: NewsItem, action: 'create' | 'delete' | 'update') => void;
}

const Alerts: React.FC<Props> = (props: Props) => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const now = new Date();
  const [showAlert] = useIonAlert();
  const [showCO2Difference, setShowCO2Difference] = useState(false);
  const [showCO2Source, setShowCO2Source] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '' });

  const handleNewsCreate = async () => {
    setLoading(true);

    const newsItem = blankNewsItem();
    const response = await firestore.collection('news').add({
      date: newsItem.date,
      headline: newsItem.headline,
      link: newsItem.link,
    });

    const id = response.id;

    props.updateNewsItem(
      {
        ...newsItem,
        id,
      },
      'create',
    );

    setLoading(false);
  };

  const handleNewsDelete = async (newsItem: NewsItem): Promise<void> => {
    try {
      setLoading(true);

      await firestore.collection('news').doc(newsItem.id).delete();

      setLoading(false);
      setToast({
        show: true,
        message: 'News item deleted',
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });

        props.updateNewsItem(newsItem, 'delete');
      }, 2000);
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Alerts - ${newsItem.id} - handleNewsDelete`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setLoading(false);
      setToast({
        show: true,
        message: 'Error - could not delete news item',
      });
      setTimeout(() => setToast({ show: false, message: '' }), 2000);
    }
  };

  const handleNewsSave = async (newsItem: NewsItem): Promise<void> => {
    try {
      setLoading(true);

      await firestore
        .collection('news')
        .doc(newsItem.id)
        .set({
          date: newsItem.date,
          headline: newsItem.headline,
          link: newsItem.link,
        } as NewsItem);

      setToast({
        show: true,
        message: 'News item changes saved',
      });

      setTimeout(() => {
        setLoading(false);
        setToast({ show: false, message: '' });
      }, 2000);
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Alerts - ${newsItem.id} - handleNewsSave`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setLoading(false);
      setToast({
        show: true,
        message: 'Error - could not save news item',
      });
      setTimeout(() => setToast({ show: false, message: '' }), 2000);
    }
  };

  return (
    <Page title="Alerts">
      <div className="alerts__siren__container">
        <IonImg alt="Air raid siren" className="alerts__siren" src={siren} />
      </div>
      <IonCard className="alerts__co2__container">
        <h2 className="alerts__co2__headline">
          {props.co2Levels && props.co2Levels.latest && !props.loading ? (
            <span className="alerts__co2__level">
              <CountUp
                decimals={2}
                duration={4}
                end={parseFloat(props.co2Levels.latest.trend)}
                onEnd={() => setShowCO2Difference(true)}
              />
              <span className="alerts__co2__ppm"> PPM</span>
            </span>
          ) : (
            <IonSpinner />
          )}
        </h2>
        <div className="alerts__co2__explainer">
          Maximum safe CO2 level: 430 PPM{' '}
          <IonIcon
            className="alerts__icon--info"
            icon={informationCircleOutlineIcon}
            onClick={() => setShowCO2Source(true)}
          />
        </div>

        {props.co2Levels &&
        props.co2Levels.latest &&
        props.co2Levels.oneMonthAgo &&
        !props.loading &&
        showCO2Difference ? (
          <h3>
            <IonIcon
              className="alerts__icon--co2-difference"
              icon={
                parseFloat(props.co2Levels.latest.trend) - parseFloat(props.co2Levels.oneMonthAgo.trend) >= 0
                  ? arrowUpIcon
                  : arrowDownIcon
              }
            />
            <CountUp
              decimals={2}
              duration={5}
              end={parseFloat(props.co2Levels.latest.trend) - parseFloat(props.co2Levels.oneMonthAgo.trend)}
            />
            <span className="alerts__co2__ppm"> PPM </span>
            in past month
          </h3>
        ) : (
          !props.loading && <IonSpinner />
        )}
      </IonCard>

      <ContentColumn
        classNameCol="alerts__news__col"
        classNameGrid="alerts__news__grid"
        classNameRow="alerts__news__row"
      >
        <div className="alerts__news">
          <h2>News to spur you into action</h2>
          {canEdit(auth) && (
            <IonButton expand="block" onClick={handleNewsCreate}>
              <IonIcon icon={addIcon} slot="start" />
              Add news item
            </IonButton>
          )}
          <IonList className="alerts__news__list">
            {props.news?.map((newsItem) =>
              canEdit(auth) ? (
                <div className="alerts__news__item--edit" key={newsItem.id}>
                  <IonInput
                    onIonChange={(event) =>
                      props.updateNewsItem(
                        {
                          ...newsItem,
                          headline: event.detail.value ?? '',
                        },
                        'update',
                      )
                    }
                    placeholder="Add a short headline"
                    value={newsItem.headline}
                  />
                  <IonInput
                    onIonChange={(event) =>
                      props.updateNewsItem(
                        {
                          ...newsItem,
                          link: event.detail.value ?? '',
                        },
                        'update',
                      )
                    }
                    placeholder="Add a link"
                    value={newsItem.link}
                  />
                  <IonDatetime
                    displayFormat="DD MMMM YYYY H:mm"
                    displayTimezone="utc"
                    onIonChange={(event) =>
                      props.updateNewsItem(
                        {
                          ...newsItem,
                          date: new Date(event.detail.value ?? ''),
                        },
                        'update',
                      )
                    }
                    value={newsItem.date.toISOString()}
                    yearValues="2021,2022,2023"
                  />
                  {newsItem.date > now && (
                    <div className="alerts__news__item--edit__future">
                      Date is in the future - news item will be hidden from other users until then
                    </div>
                  )}

                  <IonItemGroup className="update-buttons">
                    <IonButton onClick={() => handleNewsSave(newsItem)}>
                      <IonIcon icon={checkmarkIcon} slot="start" />
                      Save
                    </IonButton>
                    <IonButton
                      fill="clear"
                      onClick={() =>
                        showAlert({
                          header: 'Delete this news item?',
                          message: 'Are you sure you want to permanently delete this news item?',
                          buttons: ['Cancel', { text: 'OK', handler: () => handleNewsDelete(newsItem) }],
                        })
                      }
                    >
                      <IonIcon icon={trashIcon} slot="start" />
                      Delete
                    </IonButton>
                  </IonItemGroup>
                </div>
              ) : (
                newsItem.date <= now && (
                  <Fade key={newsItem.id}>
                    <IonItem
                      className="alerts__news__item"
                      detail
                      detailIcon={openOutlineIcon}
                      lines="none"
                      onClick={async () => {
                        await Browser.open({ url: newsItem.link });
                      }}
                    >
                      <IonLabel className="alerts__news__item__headline" color="light">
                        {newsItem.headline}
                        <div className="alerts__news__item__date">{dayJS(newsItem.date).format('D MMMM YYYY')}</div>
                      </IonLabel>
                    </IonItem>
                  </Fade>
                )
              ),
            )}
          </IonList>
        </div>
      </ContentColumn>

      <IonLoading isOpen={loading} />

      <IonToast isOpen={toast.show} message={toast.message}></IonToast>

      <Modal isOpen={showCO2Source} onClose={() => setShowCO2Source(false)}>
        <h2>The carbon clock is ticking</h2>
        <p>
          We must keep CO2 below <strong>{props.co2LevelsLimits['1.5c']}ppm</strong> (parts per million) in order to
          keep global warming to 1.5 degrees. And even that is risky.
        </p>
        <p>
          Data derived from{' '}
          <a href={props.co2LevelSource.infoUrl} rel="noopener noreferrer" target="_blank">
            {props.co2LevelSource.name}
          </a>
        </p>
      </Modal>
    </Page>
  );
};

export default Alerts;
