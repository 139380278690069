import firebase from 'firebase';
import 'firebase/auth';
import { logoFacebook, logoGoogle } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import React, { useEffect } from 'react';

import '../css/components/AuthProviderButton.css';
import { analytics, auth, authFacebook, authGoogle } from '../firebase';
import { useLocalStorage } from '../hooks';
import { ErrorMessage as ErrorMessageInterface } from '../interfaces/ErrorMessage';

interface Props {
  errorHandler: (info: ErrorMessageInterface) => void;
  mode: string;
  provider: string;
  providerReadableName: string;
}

const AuthProviderButton: React.FC<Props> = (props: Props) => {
  const [email, setEmail] = useLocalStorage('email', '');

  useEffect(() => {
    auth
      .getRedirectResult()
      .then(null, async (error) => {
        console.error('Redirect error', error);
        analytics?.logEvent('error', {
          errorLocation: `Auth Provider Button - ${props.provider} - ${props.mode} - redirect`,
          errorMessage: error.message ?? JSON.stringify(error),
        });

        if (error.email && error.credential && error.code === 'auth/account-exists-with-different-credential') {
          setEmail(error.email);
          const userExistingSignInMethods = await auth.fetchSignInMethodsForEmail(email);

          let existingAuthProviderName;
          const errorLink = {
            text: 'or request a new password to login with your email address',
            url: '/forgot-password',
          };
          if (userExistingSignInMethods.includes('google.com')) {
            existingAuthProviderName = 'Google';
          } else if (userExistingSignInMethods.includes('facebook.com')) {
            existingAuthProviderName = 'Facebook';
          } else if (userExistingSignInMethods.includes('password')) {
            existingAuthProviderName = 'email and password';
            errorLink.text = '';
            errorLink.url = '';
          }

          props.errorHandler({
            exists: true,
            preventSubmit: false,
            text: `It looks like you logged in using ${existingAuthProviderName} before. Please login again the same way `,
            link: errorLink,
          });
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSignInUp = async () => {
    let authProvider;

    switch (props.provider) {
      case 'facebook':
        authProvider = authFacebook;
        break;
      case 'google':
      default:
        authProvider = authGoogle;
        break;
    }

    props.errorHandler({
      exists: false,
      preventSubmit: false,
      text: '',
      link: {
        text: '',
        url: '',
      },
    });

    if (props.mode === 'register') {
      analytics?.logEvent('sign_up', { method: props.provider });
    } else {
      analytics?.logEvent('login', { method: props.provider });
    }

    try {
      auth
        .signInWithRedirect(authProvider)
        .then(() => {
          return auth.getRedirectResult();
        })
        .then()
        .catch((error) => {
          console.error(error);
          analytics?.logEvent('error', {
            errorLocation: `Auth Provider Button - ${props.provider} - ${props.mode} - signInWithRedirect`,
            errorMessage: error.message ?? JSON.stringify(error),
          });
        });
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Auth Provider Button - ${props.provider} - ${props.mode} - signInWithRedirect`,
        errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
      });
    }
  };

  let icon;
  switch (props.provider) {
    case 'facebook':
      icon = logoFacebook;
      break;
    case 'google':
      icon = logoGoogle;
      break;
  }

  return (
    <IonButton className="auth-provider-button" color={props.provider} expand="block" onClick={handleSignInUp}>
      <IonIcon icon={icon} slot="start" />
      Sign {props.mode === 'register' ? 'up' : 'in'} with {props.providerReadableName}
    </IonButton>
  );
};

export default AuthProviderButton;
