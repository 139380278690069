import { NewsItem } from 'climate-call-up-typescript-interfaces';
import { convertTimestamps } from 'convert-firebase-timestamp';
import dayJS from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase';

import { analytics, firestore } from '../firebase';
import { CO2Level } from '../interfaces/CO2Level';
import { CO2Levels } from '../interfaces/CO2Levels';
import { CO2LevelsLimits } from '../interfaces/CO2LevelsLimits';

dayJS.extend(customParseFormat);
dayJS.extend(relativeTime);
dayJS.extend(utc);

export function blankNewsItem(): NewsItem {
  return {
    date: new Date(),
    headline: '',
    id: '',
    link: '',
  };
}

export async function getCO2Levels(): Promise<CO2Levels | null> {
  try {
    const response = await fetch('https://global-warming.org/api/co2-api');
    const data = await response.json();

    if (data.co2) {
      const co2Data = data.co2 as CO2Level[];
      const latest = co2Data[co2Data.length - 1];

      const latestDate = dayJS(`${latest.day}/${latest.month}/${latest.year}`, 'D/M/YYYY');
      const oneMonthAgoDate = latestDate.subtract(1, 'month');
      const oneMonthAgoResult = co2Data
        .reverse()
        .filter(
          (dayData) =>
            dayData.day === oneMonthAgoDate.format('D') &&
            dayData.month === oneMonthAgoDate.format('M') &&
            dayData.year === oneMonthAgoDate.format('YYYY'),
        );
      const oneMonthAgo = oneMonthAgoResult.length ? oneMonthAgoResult[0] : null;

      return {
        latest,
        oneMonthAgo,
      };
    } else {
      console.error('CO2 level data from API does not match expected format');
      analytics?.logEvent('error', {
        errorLocation: 'alerts - getCO2Levels',
        errorMessage: 'CO2 level data from API does not match expected format',
      });

      return null;
    }
  } catch (error) {
    console.error('Error getting CO2 level from API', error);
    analytics?.logEvent('error', {
      errorLocation: 'alerts - getCO2Levels',
      errorMessage: (error as Error).message ?? JSON.stringify(error),
    });

    return null;
  }
}

export function getCO2LevelSource(): { infoUrl: string; name: string } {
  return {
    infoUrl: 'https://global-warming.org/',
    name: 'Global-Warming.org',
  };
}

export function getCO2LevelsLimits(): CO2LevelsLimits {
  return { '1.5c': 430, '2c': 450 };
}

export async function indexNews(): Promise<NewsItem[] | null> {
  try {
    const ref = firestore.collection('news');
    const news = await ref
      .orderBy('date', 'desc')
      .get()
      .then((snapshot) => {
        const news = snapshot.docs.map((doc) => {
          let newsItem = doc.data();
          newsItem = convertTimestamps(newsItem) as NewsItem;

          return {
            id: doc.id,
            ...newsItem,
          } as NewsItem;
        });

        return news;
      })
      .catch((error) => {
        console.error('Error getting news', error);
        analytics?.logEvent('error', {
          errorLocation: 'alerts - indexNews',
          errorMessage: error.message ?? JSON.stringify(error),
        });

        return null;
      });

    return news;
  } catch (error) {
    console.error('Error getting news', error);
    analytics?.logEvent('error', {
      errorLocation: 'alerts - indexNews',
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return null;
  }
}
