import React from 'react';
import { IonButton, IonButtons, IonCol, IonGrid, IonRow, IonText, isPlatform } from '@ionic/react';

import '../css/components/CookieConsent.css';

interface Props {
  updateAnalyticsConsent: (value: boolean) => void;
}

const CookieConsent: React.FC<Props> = (props: Props) => {
  return (
    <div className="cookie-consent" style={{ bottom: isPlatform('ios') ? '51px' : '57px' }}>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol className="cookie-consent__text" size="12" sizeSm="auto">
            <IonText>We use cookies to follow your progress on climate action.</IonText>
          </IonCol>
          <IonCol className="cookie-consent__buttons" size="12" sizeSm="auto">
            <IonButtons>
              <IonButton color="primary" fill="solid" onClick={() => props.updateAnalyticsConsent(true)} strong>
                OK no problem
              </IonButton>
              <IonButton
                color="dark"
                onClick={() => {
                  props.updateAnalyticsConsent(false);
                  setTimeout(() => {
                    location.reload();
                  }, 500);
                }}
                strong
              >
                No thanks
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default CookieConsent;
