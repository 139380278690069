import { flame as flameIcon } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import React from 'react';
import CountUp from 'react-countup';

import '../css/components/ImpactScore.css';

interface Props {
  caption: string;
  display: 'body' | 'sidebar';
  points: number;
}

const ImpactScore: React.FC<Props> = (props: Props) => (
  <div className={`impact-score impact-score--${props.display}`}>
    <div className="impact-score__score">
      <IonIcon className="impact-score__score__icon" color="secondary" icon={flameIcon} size="large" />
      <CountUp duration={4} end={props.points} />
    </div>
    <div className="impact-score__caption">{props.caption}</div>
  </div>
);

export default ImpactScore;
