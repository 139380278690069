import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';

import config from '../config.json';
import '../css/components/EnlistBegin.css';
import ContentColumn from './ContentColumn';
import ErrorMessage from './ErrorMessage';
import { useLocalStorage } from '../hooks';
import { countries, detectUserCountry, emojiFlag } from '../utils/geography';

interface Props {
  display: 'modal' | 'sidebar';
  onSubmit?: () => void;
}

const EnlistBegin: React.FC<Props> = (props: Props) => {
  const errorTextDefault = 'Please add all your details';
  const errorLinkDefault = {
    text: '',
    url: '',
  };
  const [error, setError] = useState({
    exists: false,
    preventSubmit: false,
    text: errorTextDefault,
    link: errorLinkDefault,
  });
  const [country, setCountry] = useLocalStorage('country', config.defaultCountry);
  const [firstName, setFirstName] = useLocalStorage('firstName', '');
  const history = useHistory();
  const [lastName, setLastName] = useLocalStorage('lastName', '');

  useEffect(() => {
    detectUserCountry()
      .then((countryDetected: string | null) => setCountry(countryDetected ?? country))
      .catch((error) => console.error(error));
  }, []);

  const handleFormSubmit = (): void => {
    if (!firstName || !lastName) {
      const text = `Please add your ${!firstName ? 'first name' : ''} ${!firstName && !lastName ? 'and' : ''} ${
        !lastName ? 'last name' : ''
      } above`;

      setError({
        exists: true,
        preventSubmit: true,
        text,
        link: errorLinkDefault,
      });

      return;
    } else {
      setError({
        exists: false,
        preventSubmit: false,
        text: errorTextDefault,
        link: errorLinkDefault,
      });
    }

    if (props.onSubmit) props.onSubmit();
    history.push('/enlist/register');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>): void => {
    if (event.key === 'Enter') {
      handleFormSubmit();
    }
  };

  const renderLoginButton = () => (
    <IonButton
      expand="block"
      fill="clear"
      onClick={() => {
        if (props.onSubmit) props.onSubmit();
      }}
      routerLink="/login"
    >
      Login instead
    </IonButton>
  );

  return (
    <div className="enlist__begin">
      <IonText className="enlist__begin__intro">
        <h2 className="enlist__begin__header">Enlist!</h2>
        <p className="enlist__begin__blurb">And help fix the climate emergency.</p>
      </IonText>

      <ContentColumn containerWidth={props.display === 'sidebar' ? 'narrow' : 'normal'}>
        <div className="enlist__begin__sign-up-encourage">Sign up to get insider details of actions.</div>
        <IonItem>
          <IonLabel className={!firstName && error.exists ? 'label--error' : ''} position="floating">
            First name
          </IonLabel>
          <IonInput
            autocapitalize="words"
            autocomplete="given-name"
            onIonChange={(event) => setFirstName(event.detail.value ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            value={firstName}
          />
        </IonItem>

        <IonItem className="enlist__begin__last-name">
          <IonLabel className={!lastName && error.exists ? 'label--error' : ''} position="floating">
            Last name
          </IonLabel>
          <IonInput
            autocapitalize="words"
            autocomplete="family-name"
            onIonChange={(event) => setLastName(event.detail.value ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            value={lastName}
          />
        </IonItem>

        <IonItem lines="none">
          <IonLabel position="floating">Country</IonLabel>
          <IonSelect onIonChange={(event) => setCountry(event.detail.value ?? '')} value={country}>
            {countries().map(([code, name]) => (
              <IonSelectOption key={code} value={code}>
                {emojiFlag(code)} {name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        <ErrorMessage
          exists={error.exists}
          link={{ text: error.link.text, url: error.link.url }}
          preventSubmit={error.preventSubmit}
          text={error.text}
        />

        <IonButton className="enlist__begin__button--submit" expand="block" onClick={handleFormSubmit}>
          Next
        </IonButton>

        {props.display === 'modal' && (
          <div>
            <IonText>
              <hr />
              <p className="enlist__already">Already enlisted?</p>
            </IonText>

            {renderLoginButton()}
          </div>
        )}
      </ContentColumn>
    </div>
  );
};

export default EnlistBegin;
