import firebase from 'firebase';
import { IonButton, IonInput, IonItem, IonLabel, IonLoading, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import ErrorMessage from '../components/ErrorMessage';
import { analytics, auth } from '../firebase';
import { useLocalStorage } from '../hooks';
import Page from './Page';

const ForgotPasswordReset: React.FC = () => {
  const history = useHistory();
  const url = new URL(window.location.href);
  const mode = url.searchParams.get('mode');
  const oobCode = url.searchParams.get('oobCode');
  if (mode !== 'resetPassword' || !oobCode) {
    return <Redirect to="/hq" />;
  }

  const [email, setEmail] = useLocalStorage('email', '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const errorTextDefault = 'Sorry, your password reset link is invalid - it may have expired. Please ';
  const errorLinkDefault = {
    text: 'try resetting your password again',
    url: '/forgot-password',
  };
  const [error, setError] = useState({
    exists: false,
    preventSubmit: false,
    validLink: true,
    text: errorTextDefault,
    link: errorLinkDefault,
  });

  useEffect(() => {
    setLoading(true);

    try {
      auth
        .verifyPasswordResetCode(oobCode)
        .then(
          (accountEmail) => {
            setEmail(accountEmail);
            setLoading(false);
          },
          (error) => {
            console.error(error);
            analytics?.logEvent('error', {
              errorLocation: `ForgotPasswordReset - ${email} - verifyPasswordResetCode`,
              errorMessage: error.message ?? JSON.stringify(error),
            });

            setError({
              exists: true,
              preventSubmit: true,
              validLink: false,
              text: errorTextDefault,
              link: errorLinkDefault,
            });
            setLoading(false);
          },
        )
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `ForgotPasswordReset - ${email} - verifyPasswordResetCode`,
        errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
      });

      setError({
        exists: true,
        preventSubmit: true,
        validLink: false,
        text: errorTextDefault,
        link: errorLinkDefault,
      });
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>): void => {
    if (event.key === 'Enter') {
      handleSetPassword();
    }
  };

  const handleSetPassword = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError({ exists: false, preventSubmit: false, validLink: true, text: error.text, link: errorLinkDefault });

      await auth.confirmPasswordReset(oobCode, password);

      await auth.signInWithEmailAndPassword(email, password);

      setLoading(false);

      history.push('/forgot-password/reset/complete');
    } catch (error) {
      console.error((error as firebase.auth.Error).code);
      analytics?.logEvent('error', {
        errorLocation: `ForgotPasswordReset - ${email} - handleSetPassword`,
        errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
      });

      setLoading(false);

      let errorText;
      const errorLink = {
        text: '',
        url: '',
      };
      switch ((error as firebase.auth.Error).code) {
        case 'auth/weak-password':
          errorText = 'Please choose a stronger password';
          break;
        default:
          errorText = errorTextDefault;
          break;
      }
      setError({ exists: true, preventSubmit: false, validLink: true, text: errorText, link: errorLink });
    }
  };

  const validateForm = (): boolean => {
    return !!password;
  };

  return (
    <Page title="Reset password">
      <IonText>
        <h2>Set a new password</h2>
        {error.validLink ? <p>{email}</p> : null}
      </IonText>

      {error.validLink ? (
        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            onIonChange={(event) => setPassword(event.detail.value?.trim() ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            type="password"
            value={password}
          />
        </IonItem>
      ) : null}

      <ErrorMessage
        exists={error.exists}
        link={{ text: error.link.text, url: error.link.url }}
        preventSubmit={error.preventSubmit}
        text={error.text}
      />

      {error.validLink ? (
        <IonButton disabled={!validateForm()} expand="block" onClick={handleSetPassword} type="submit">
          Set password
        </IonButton>
      ) : null}

      <IonLoading isOpen={loading} />
    </Page>
  );
};

export default ForgotPasswordReset;
