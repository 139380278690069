import React from 'react';
import { IonText } from '@ionic/react';

import { useLocalStorage } from '../hooks';
import Page from './Page';

const ForgotPasswordResetComplete: React.FC = () => {
  const [email] = useLocalStorage('email', '');

  return (
    <Page title="Password reset">
      <IonText>
        <h2>Password reset successfully</h2>
        <p>We&apos;ve reset your password for {email}</p>
      </IonText>
    </Page>
  );
};

export default ForgotPasswordResetComplete;
