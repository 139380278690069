import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { AuthContext } from '../auth';
import LinkExternal from '../components/LinkExternal';
import config from '../config.json';
import '../css/pages/Page.css';
import logo from '../images/logo-200.png';
import { PageContext } from '../interfaces/PageContext';
import { routesList } from '../routes';

interface Params {
  id: string;
}

interface Props {
  children?: ReactNode;
  className?: string;
  context?: PageContext;
  fullWidth?: boolean;
  title: string;
}

const Page: React.FC<Props> = (props: Props) => {
  const auth = useContext(AuthContext);
  const { id } = useParams<Params>();
  const history = useHistory();
  history.listen((location) => {
    updateBrowserPageTitle(location.pathname);
  });
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = (event: { detail: { scrollTop: number } }): void =>
    setScrolled(Boolean(event?.detail?.scrollTop && event.detail.scrollTop > 40));

  const updateBrowserPageTitle = (pathname: string): void => {
    const path = id ? pathname.replace(id, ':id') : pathname;
    const page = routesList(props.context).find((page) => page.path === path);
    document.title = `${page ? `${page.browserPageTitle ?? page.title} - ` : ''} ${config.siteName} - ${
      config.titleDescription
    }`;
  };

  useEffect(() => {
    updateBrowserPageTitle(document.location.pathname);
  }, []);

  return (
    <IonPage className={`page ${props.className ?? ''}`}>
      <IonHeader mode="ios">
        <IonToolbar className={`toolbar ${scrolled ? 'toolbar--scrolled' : ''}`}>
          {history.location.pathname !== '/hq' && (
            <IonButtons slot="start">
              <IonBackButton mode={isPlatform('ios') ? 'ios' : 'md'} />
            </IonButtons>
          )}
          <IonTitle>
            <Link to={auth.loggedIn ? '/hq' : '/enlist'}>
              <img alt={`${config.siteName} logo`} className="logo" src={logo} />
            </Link>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className={`content ${props.fullWidth ? 'content--full-width' : ''}`}
        fullscreen
        onIonScroll={handleScroll}
        scrollEvents
      >
        <IonGrid className="content__grid">
          <IonRow className="ion-justify-content-center">
            <IonCol className="content__grid__col" size="12" size-md="10" sizeLg="8" sizeXl="8">
              {props.title !== 'Enlist!' && <h1 className="title">{props.title}</h1>}
              {props.children}
              <p className="footer-links">
                <LinkExternal to={config.donateUrl}>Support us</LinkExternal> <Link to="/privacy">Privacy policy</Link>{' '}
                <Link to="/terms">Terms & conditions</Link>{' '}
                <span className="ion-text-nowrap">
                  &copy; {config.siteName} {new Date().getFullYear()}
                </span>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Page;
