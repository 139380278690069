import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

import '../css/components/OrDivider.css';

const OrDivider: React.FC = () => (
  <IonGrid className="or-divider">
    <IonRow>
      <IonCol>
        <div className="or-divider__line__container">
          <div className="or-divider__line"></div>
        </div>
      </IonCol>
      <IonCol size="1">
        <p className="or-divider__text">or</p>
      </IonCol>
      <IonCol>
        <div className="or-divider__line__container">
          <div className="or-divider__line"></div>
        </div>
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default OrDivider;
