import React from 'react';
import { Link } from 'react-router-dom';

import '../css/components/CardImage.css';

interface Props {
  className?: string;
  credit?: string;
  display?: 'actionPage' | 'hq';
  header?: string;
  routerLink?: string;
  url: string;
}

interface Style {
  backgroundImage: string;
  paddingTop?: string;
}

const CardImage: React.FC<Props> = (props: Props) => {
  const style = {
    backgroundImage: `url(${props.url})`,
  } as Style;
  if (props.display === 'hq' && window.innerWidth >= 1200)
    style.paddingTop = `${Math.round(135000 / window.innerWidth)}%`;

  const renderImage = (): JSX.Element => {
    return (
      <div
        className={`card-image ${props.display ? `card-image--${props.display}` : ''} ${props.className ?? ''}`}
        style={style}
      >
        <div className="card-image__content">
          <h2 className={`card-image__header ${props.display ? `card-image--${props.display}__header` : ''}`}>
            {props.header ?? ''}
          </h2>
          <div className="card-image__credit">{props.credit ?? ''}</div>
        </div>
      </div>
    );
  };

  return props.routerLink ? <Link to={props.routerLink}>{renderImage()}</Link> : renderImage();
};

export default CardImage;
