import firebase from 'firebase';
import { IonButton, IonInput, IonItem, IonLabel, IonLoading, IonText } from '@ionic/react';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthProviderButton from '../components/AuthProviderButton';
import ErrorMessage from '../components/ErrorMessage';
import ContentColumn from '../components/ContentColumn';
import OrDivider from '../components/OrDivider';
import '../css/pages/EnlistRegister.css';
import { analytics, auth, firestore } from '../firebase';
import { useLocalStorage } from '../hooks';
import Page from './Page';
import { validateEmailAddress } from '../validators';

const EnlistRegister: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useLocalStorage('email', '');
  const [country] = useLocalStorage('country', '');
  const [firstName] = useLocalStorage('firstName', '');
  const [lastName] = useLocalStorage('lastName', '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [loading, setLoading] = useState(false);
  const errorTextDefault = 'Sorry, we could not register you';
  const errorTextPasswordMatch = 'Please make sure the passwords match';
  const errorLinkDefault = {
    text: '',
    url: '',
  };
  const [error, setError] = useState({
    exists: false,
    preventSubmit: false,
    text: errorTextDefault,
    link: errorLinkDefault,
  });
  const url = new URL(window.location.href);
  const adminParam = url.searchParams.get('admin');

  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>): void => {
    if (event.key === 'Enter') {
      handleRegister();
    }
  };

  const handleRegister = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError({ exists: false, preventSubmit: false, text: errorTextDefault, link: errorLinkDefault });

      const credential = await auth.createUserWithEmailAndPassword(email, password);

      if (credential?.user?.uid) {
        try {
          await firestore
            .collection('users')
            .doc(credential?.user?.uid)
            .set({
              consent: {
                email: true,
              },
              profile: {
                country: country.toLowerCase(),
                email,
                firstName,
                lastName,
              },
            });

          analytics?.logEvent('sign_up', { method: 'email' });

          history.push('/enlist/success');
        } catch (error) {
          console.error(error);
          analytics?.logEvent('error', {
            errorLocation: `EnlistRegister - ${email} - handleRegister`,
            errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
          });

          setError({
            exists: true,
            preventSubmit: false,
            text: "Sorry, we couldn't add you as a user",
            link: errorLinkDefault,
          });
        }
      }

      setLoading(false);
    } catch (error) {
      console.error('Login error', (error as firebase.auth.Error).code);
      analytics?.logEvent('error', {
        errorLocation: `EnlistRegister - ${email} - handleRegister`,
        errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
      });

      setLoading(false);

      let errorText;
      let errorLink = errorLinkDefault;
      switch ((error as firebase.auth.Error).code) {
        case 'auth/weak-password':
          errorText = 'Please choose a stronger password';
          break;
        case 'auth/email-already-in-use':
          errorText = 'You have already enlisted.';
          errorLink = {
            text: 'Please log in here instead.',
            url: '/login',
          };
          break;
        default:
          errorText = errorTextDefault;
          break;
      }
      setError({ exists: true, preventSubmit: false, text: errorText, link: errorLink });
    }
  };

  const validateEmail = (): boolean => {
    const valid = validateEmailAddress(email);

    if (!valid) {
      setError({ exists: true, preventSubmit: true, text: 'Please check your email address', link: errorLinkDefault });
    } else {
      setError({ exists: false, preventSubmit: false, text: error.text, link: errorLinkDefault });
    }

    return valid;
  };

  const validateFieldsCompleted = (): boolean => {
    let error = {
      exists: false,
      preventSubmit: false,
      text: errorTextDefault,
      link: errorLinkDefault,
    };
    const fieldsIncomplete = [];
    const fieldsTooShort = [];

    if (!firstName) fieldsIncomplete.push('first name');
    if (!lastName) fieldsIncomplete.push('last name');
    if (!email) fieldsIncomplete.push('email address');
    if (!password) {
      fieldsIncomplete.push('password');
    } else if (password.length < 6) {
      fieldsTooShort.push('password');
    }
    if (!passwordConfirm) {
      fieldsIncomplete.push('confirm password');
    } else if (passwordConfirm.length < 6) {
      fieldsTooShort.push('confirm password');
    }

    if (fieldsIncomplete.length) {
      error = {
        exists: true,
        preventSubmit: true,
        text: `Please complete: ${fieldsIncomplete.join(', ')}`,
        link: errorLinkDefault,
      };
    } else if (fieldsTooShort.length) {
      error = {
        exists: true,
        preventSubmit: true,
        text: `Please check: ${fieldsTooShort.join(', ')} must be 6 characters or longer`,
        link: errorLinkDefault,
      };
    }
    setError(error);

    return !Boolean(fieldsIncomplete.length) && !Boolean(fieldsTooShort.length);
  };

  const validateForm = (): boolean => {
    if (!validateFieldsCompleted()) return false;
    if (!validateEmail()) return false;
    if (!validatePasswordMatch()) return false;

    return true;
  };

  const validatePasswordMatch = (): boolean => {
    if (passwordConfirm !== password) {
      setError({
        exists: true,
        preventSubmit: true,
        text: errorTextPasswordMatch,
        link: errorLinkDefault,
      });

      return false;
    } else {
      if (error.exists && error.text === errorTextPasswordMatch)
        setError({
          exists: false,
          preventSubmit: false,
          text: errorTextDefault,
          link: errorLinkDefault,
        });

      return true;
    }
  };

  return (
    <Page title="Enlist">
      <IonText>
        <h1>Let&apos;s go, {firstName}</h1>
        <p className="enlist__register__blurb">Sign up to get insider details of actions and monitor your impact.</p>
        <p className="enlist__register__blurb">
          We don&apos;t share your details and we&apos;ll only email you important updates.
        </p>
      </IonText>

      <ContentColumn>
        {adminParam && (
          <React.Fragment>
            <AuthProviderButton
              errorHandler={setError}
              mode="register"
              provider="facebook"
              providerReadableName="Facebook"
            />
            <AuthProviderButton
              errorHandler={setError}
              mode="register"
              provider="google"
              providerReadableName="Google"
            />

            <OrDivider />
          </React.Fragment>
        )}

        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            autocomplete="email"
            onIonBlur={validateEmail}
            onIonChange={(event) => setEmail(event.detail.value?.trim() ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            type="email"
            value={email}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="floating">Password (at least 6 characters)</IonLabel>
          <IonInput
            autocomplete="new-password"
            clearOnEdit={false}
            onIonChange={(event) => setPassword(event.detail.value?.trim() ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            type="password"
            value={password}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="floating">Confirm password</IonLabel>
          <IonInput
            clearOnEdit={false}
            onIonBlur={validatePasswordMatch}
            onIonChange={(event) => setPasswordConfirm(event.detail.value?.trim() ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            type="password"
            value={passwordConfirm}
          />
        </IonItem>

        <ErrorMessage
          exists={error.exists}
          link={{ text: error.link.text, url: error.link.url }}
          preventSubmit={error.preventSubmit}
          text={error.text}
        />

        <IonButton className="enlist__register__button--submit" expand="block" onClick={handleRegister}>
          Enlist
        </IonButton>

        <IonText>
          <hr />
          <p className="enlist__already">Already enlisted?</p>
        </IonText>

        <IonButton expand="block" fill="clear" routerLink="/login">
          Login instead
        </IonButton>
      </ContentColumn>

      <IonLoading isOpen={loading} />
    </Page>
  );
};

export default EnlistRegister;
