import { useState } from 'react';

export function useLocalStorage(key: string, defaultValue: string): [string, (newValue: string) => void] {
  const getInitialValue = () => localStorage.getItem(key) ?? defaultValue;
  const [value, setValue] = useState(getInitialValue);
  const setAndStoreValue = (newValue: string) => {
    setValue(newValue);
    localStorage.setItem(key, newValue);
  };

  return [value, setAndStoreValue];
}
