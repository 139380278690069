import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { ReactNode } from 'react';

import '../css/components/ContentColumn.css';

interface Props {
  children: ReactNode;
  classNameCol?: string;
  classNameGrid?: string;
  classNameRow?: string;
  containerWidth?: 'modal' | 'narrow' | 'normal';
  id?: string;
}

const TextBlockContainer: React.FC<Props> = (props: Props) => (
  <IonGrid className={`content-column ${props.classNameGrid ?? ''}`} id={props.id ?? ''}>
    <IonRow className={`ion-justify-content-center ${props.classNameRow ?? ''}`}>
      <IonCol
        className={props.classNameCol ?? ''}
        size="12"
        sizeSm={props.containerWidth === 'narrow' ? '12' : props.containerWidth === 'modal' ? '10' : '10'}
        sizeMd={props.containerWidth === 'narrow' ? '12' : props.containerWidth === 'modal' ? '10' : '10'}
        sizeLg={props.containerWidth === 'narrow' ? '12' : props.containerWidth === 'modal' ? '8' : '9'}
        sizeXl={props.containerWidth === 'narrow' ? '12' : props.containerWidth === 'modal' ? '8' : '9'}
      >
        {props.children}
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default TextBlockContainer;
