import { Browser } from '@capacitor/browser';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  to: string;
}

const LinkExternal: React.FC<Props> = (props: Props) => (
  <span
    className="link link-external"
    onClick={async () => {
      await Browser.open({ url: props.to });
    }}
  >
    {props.children}
  </span>
);

export default LinkExternal;
