import { convertTimestamps } from 'convert-firebase-timestamp';
import dayJS from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase';
import { Action as ActionInterface, ActionTotal as ActionTotalInterface } from 'climate-call-up-typescript-interfaces';

import { analytics, firestore } from '../firebase';
import { ActionFeedback as ActionFeedbackInterface } from '../interfaces/ActionFeedback';

dayJS.extend(relativeTime);
dayJS.extend(utc);

export function blankAction(): ActionInterface {
  const today = dayJS().utc().startOf('day').toDate();
  const later = dayJS().utc().endOf('day').add(3, 'month').toDate();

  return {
    dateDisplayFormat: 'day',
    dateExpires: later,
    dateFrom: today,
    description: '',
    difficulty: 1,
    id: '',
    image: '',
    imageCredit: '',
    impactPoints: 10,
    link: '',
    timeLimited: true,
    status: 'draft',
    tips: '',
    title: '',
    type: 'onlineExternal',
  } as ActionInterface;
}

export async function indexActions(): Promise<ActionInterface[] | null> {
  try {
    const actionsRef = firestore.collection('actions');
    const actions = await actionsRef
      .orderBy('dateFrom', 'desc')
      .get()
      .then((snapshot) => {
        const actions = snapshot.docs.map((doc) => {
          let action = doc.data();
          action = convertTimestamps(action) as ActionInterface;

          return {
            ...action,
            id: doc.id,
          } as ActionInterface;
        });

        return actions;
      })
      .catch((error) => {
        console.error('Error getting actions', error);
        analytics?.logEvent('error', {
          errorLocation: `actions - indexActions`,
          errorMessage: error.message ?? JSON.stringify(error),
        });

        return null;
      });

    return actions;
  } catch (error) {
    console.error('Error getting actions', error);
    analytics?.logEvent('error', {
      errorLocation: `actions - indexActions`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return null;
  }
}

export async function getActionFeedback(actionId: string): Promise<ActionFeedbackInterface[] | null> {
  try {
    const ref = firestore.collection('actions').doc(actionId).collection('userFeedback');
    const feedback = await ref
      .get()
      .then((snapshot) => {
        const feedback = snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            userId: doc.id,
          } as ActionFeedbackInterface;
        });

        return feedback;
      })
      .catch((error) => {
        console.error('Error getting action feedback', error);
        analytics?.logEvent('error', {
          errorLocation: `actions - ${actionId} - getActionFeedback`,
          errorMessage: error.message ?? JSON.stringify(error),
        });

        return [];
      });

    return feedback;
  } catch (error) {
    console.error('Error getting action feedback', error);
    analytics?.logEvent('error', {
      errorLocation: `actions - ${actionId} - getActionFeedback`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return [];
  }
}

export async function getActionTotals(actionId: string): Promise<ActionTotalInterface[] | null> {
  try {
    const ref = firestore.collection('actions').doc(actionId).collection('totals');
    const totals = await ref
      .get()
      .then((snapshot) => {
        const totals = snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          } as ActionTotalInterface;
        });

        return totals;
      })
      .catch((error) => {
        console.error('Error getting action totals', error);
        analytics?.logEvent('error', {
          errorLocation: `actions - ${actionId} - getActionTotals`,
          errorMessage: error.message ?? JSON.stringify(error),
        });

        return [];
      });

    return totals;
  } catch (error) {
    console.error('Error getting action totals', error);
    analytics?.logEvent('error', {
      errorLocation: `actions - ${actionId} - getActionTotals`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return [];
  }
}
