import React from 'react';
import { useParams } from 'react-router';
import { Action as ActionInterface, UserAction } from 'climate-call-up-typescript-interfaces';

import Loading from '../components/Loading';
import ActionComponent from '../components/Action';
import { analytics } from '../firebase';
import Page from './Page';

interface Params {
  id: string;
}

interface Props {
  edit: boolean;
  loading: boolean;
  actions: ActionInterface[] | null;
  updateAction: (actionUpdated: ActionInterface, instruction: 'create' | 'delete' | 'update') => void;
  updateImpactPoints?: () => void;
  updateUserAction: (userActionUpdated: UserAction, instruction: 'create' | 'delete' | 'update') => void;
  userActions: UserAction[] | null;
}

const Action: React.FC<Props> = (props: Props) => {
  let action,
    userAction = null;
  const { id } = useParams<Params>();

  if (props.actions?.length) {
    const foundAction = props.actions.filter((action) => action.id === id);
    if (foundAction.length) {
      action = foundAction[0];
    } else {
      console.error('Action does not exist');
      analytics?.logEvent('error', {
        errorLocation: `Action - ${id}`,
        errorMessage: 'Action does not exist',
      });
    }
  }

  if (props.userActions?.length) {
    const foundUserAction = props.userActions.filter((userAction) => userAction.id === id);
    if (foundUserAction.length) {
      userAction = foundUserAction[0];
    }
  }

  return (
    <Page context={{ action }} fullWidth title="Action Detail">
      {props.loading ? (
        <Loading />
      ) : action ? (
        <ActionComponent
          display="page"
          edit={props.edit}
          action={action}
          updateAction={props.updateAction}
          updateImpactPoints={props.updateImpactPoints}
          userAction={userAction}
          updateUserAction={props.updateUserAction}
        />
      ) : (
        'Could not find action'
      )}
    </Page>
  );
};

export default Action;
