import { Content } from 'climate-call-up-typescript-interfaces';
import firebase from 'firebase';

import { analytics, firestore } from '../firebase';

export async function getContent(id: string): Promise<Content | null> {
  try {
    let content: Content;

    const ref = firestore.collection('content').doc(id);
    const databaseContent = await ref.get();

    if (databaseContent.exists) {
      content = databaseContent.data() as Content;

      return content;
    }

    return null;
  } catch (error) {
    console.error(`Error getting content - ${id}`, error);
    analytics?.logEvent('error', {
      errorLocation: `content - ${id} - getContent`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return null;
  }
}
