import { close as closeIcon } from 'ionicons/icons';
import { IonIcon, IonModal } from '@ionic/react';
import React, { MouseEventHandler, ReactNode } from 'react';

import '../css/components/Modal.css';

interface Props {
  children?: ReactNode;
  cssClass?: string;
  hideCloseButton?: boolean;
  isOpen: boolean;
  onClose?: MouseEventHandler<HTMLIonIconElement>;
}

const Modal: React.FC<Props> = (props: Props) => (
  <IonModal backdropDismiss={!props.hideCloseButton} cssClass={`modal ${props.cssClass}`} isOpen={props.isOpen}>
    <div className="ion-padding modal__inner">
      {!props.hideCloseButton && (
        <div className="close-button__container">
          <IonIcon className="close-button" icon={closeIcon} onClick={props.onClose} size="large" />
        </div>
      )}

      <div className="modal__inner__content">{props.children}</div>
    </div>
  </IonModal>
);

export default Modal;
