import React from 'react';
import { IonItem, IonItemGroup, IonText } from '@ionic/react';

import ContentColumn from '../components/ContentColumn';
import '../css/pages/EnlistSuccess.css';
import { useLocalStorage } from '../hooks';
import Page from './Page';

const EnlistSuccess: React.FC = () => {
  const [firstName] = useLocalStorage('firstName', '');

  return (
    <Page title="You are enlisted!">
      <IonText>
        <h1>Welcome{firstName && `, ${firstName}`}!</h1>
        <p className="ion-text-center">And thanks for stepping up.</p>
        <ContentColumn>
          <IonItemGroup>
            <IonItem button className="enlist__success__item" color="primary" detail routerLink="/actions">
              Start with current actions and check your email for new ones
            </IonItem>
            <IonItem button className="enlist__success__item" color="primary" detail routerLink="/alerts">
              Stay on top of key climate news that will spur you to action
            </IonItem>
            <IonItem button className="enlist__success__item" color="primary" detail routerLink="/recruit">
              Invite friends to stop worrying and act with you instead
            </IonItem>
          </IonItemGroup>
        </ContentColumn>
      </IonText>
    </Page>
  );
};

export default EnlistSuccess;
