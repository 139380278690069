export function getFirstWord(text: string | null): string | null {
  return getFirstXWords(text, 1);
}

export function getFirstXWords(text: string | null, numberOfWords: number): string | null {
  if (!text) return null;

  text = text.trim();
  const words = text.split(' ');
  const firstXWords = words.slice(0, numberOfWords);

  return words.length ? firstXWords.join(' ') : '';
}
