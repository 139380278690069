import { getCodeList, overwrite } from 'country-list';
import * as emojiFlags from 'emoji-flags';

import config from '../config.json';
import { analytics } from '../firebase';

overwrite([
  {
    code: 'gb',
    name: 'United Kingdom',
  },
  {
    code: 'us',
    name: 'United States',
  },
]);

export function countries(): string[][] {
  const countriesList = Object.entries(getCodeList()).sort((a, b) => (a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0));

  return countriesList;
}

export async function detectUserCountry(): Promise<string | null> {
  try {
    const response = await fetch(`${config.ipServiceUrl}?key=${config.ipServiceApiKey}`);

    if (response.ok && response.status === 200) {
      const responseJson = await response.json();

      return responseJson?.location?.country?.code?.toLowerCase() ?? null;
    } else {
      throw new Error('Error reaching IP service API');
    }
  } catch (error) {
    console.error(error);
    analytics?.logEvent('error', {
      errorLocation: `geography - detectUserCountry`,
      errorMessage: (error as Error).message ?? JSON.stringify(error),
    });

    return null;
  }
}

export function emojiFlag(countryCode: string): string {
  const flag = emojiFlags.countryCode(countryCode);

  return flag.emoji;
}
