import { arrowForward as arrowForwardIcon } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';

import config from '../config.json';
import ContentColumn from './ContentColumn';
import '../css/components/IntroBlurb.css';

const IntroBlurb: React.FC = () => (
  <ContentColumn classNameGrid="important intro-blurb">
    <h3>The {config.siteName} asks everyone to step up</h3>
    <p>
      We face an existential emergency that requires a WW2-level mobilisation: we only have a short window left in which
      to start properly reversing the damage.
    </p>
    <p>Individual behaviour has its place. But the huge scale of the crisis now calls for collective power. </p>
    <p>
      We need to act together as citizens to put unstoppable pressure on our governments, and to counteract lobbying
      from fossil fuel and other interests.
    </p>
    <p>We point you to the most effective actions.</p>
    <p>The most innovative approaches.</p>
    <p>The right battles to fight.</p>
    <p>The best use of your precious time.</p>
    <p>On the collective journey to restore our planet&apos;s climate.</p>

    <div className="intro-blurb__buttons">
      <IonButton fill="clear" routerLink="/about">
        <IonIcon icon={arrowForwardIcon} slot="start" />
        About {config.siteName}
      </IonButton>
      <IonButton fill="clear" routerLink="/launch">
        <IonIcon icon={arrowForwardIcon} slot="start" />
        Launch campaign
      </IonButton>
    </div>
  </ContentColumn>
);

export default IntroBlurb;
