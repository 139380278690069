import firebase from 'firebase';
import { IonButton, IonInput, IonItem, IonLabel, IonLoading, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMessage from '../components/ErrorMessage';
import { analytics, auth } from '../firebase';
import { useLocalStorage } from '../hooks';
import Page from './Page';
import { validateEmailAddress } from '../validators';

const Login: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useLocalStorage('email', '');
  const [loading, setLoading] = useState(false);
  const errorTextDefault = 'Sorry, we ran into a problem - please try again';
  const errorLinkDefault = {
    text: '',
    url: '',
  };
  const [error, setError] = useState({
    exists: false,
    preventSubmit: false,
    text: errorTextDefault,
    link: errorLinkDefault,
  });

  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>): void => {
    if (event.key === 'Enter') {
      handlePasswordReset();
    }
  };

  const handlePasswordReset = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError({ exists: false, preventSubmit: false, text: error.text, link: errorLinkDefault });

      await auth.sendPasswordResetEmail(email);

      setLoading(false);

      history.push('/forgot-password/sent');
    } catch (error) {
      console.error((error as firebase.auth.Error).code);
      analytics?.logEvent('error', {
        errorLocation: `ForgotPassword - ${email} - handlePasswordReset`,
        errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
      });

      setLoading(false);

      let errorText;
      let errorLink = errorLinkDefault;
      switch ((error as firebase.auth.Error).code) {
        case 'auth/user-not-found':
          errorText = "Can't find your details - please check your email address or ";
          errorLink = {
            text: 'enlist as a new user here.',
            url: '/enlist',
          };
          break;
        case 'auth/wrong-password':
          errorText = 'Wrong password - please check and try again';
          break;
        default:
          errorText = errorTextDefault;
          break;
      }
      setError({ exists: true, preventSubmit: false, text: errorText, link: errorLink });
    }
  };

  const validateEmail = (): boolean => {
    const valid = validateEmailAddress(email);

    if (!valid) {
      setError({ exists: true, preventSubmit: true, text: 'Please check your email address', link: errorLinkDefault });
    } else {
      setError({ exists: false, preventSubmit: false, text: error.text, link: errorLinkDefault });
    }

    return valid;
  };

  const validateForm = (): boolean => {
    return !!email && validateEmailAddress(email);
  };

  return (
    <Page title="Reset password">
      <IonText>Forgotten your password? Reset it here</IonText>
      <IonItem>
        <IonLabel position="floating">Email</IonLabel>
        <IonInput
          onIonBlur={validateEmail}
          onIonChange={(event) => setEmail(event.detail.value?.trim() ?? '')}
          onKeyPress={(event) => handleKeyPress(event)}
          type="email"
          value={email}
        />
      </IonItem>

      <ErrorMessage
        exists={error.exists}
        link={{ text: error.link.text, url: error.link.url }}
        preventSubmit={error.preventSubmit}
        text={error.text}
      />

      <IonButton disabled={!validateForm()} expand="block" onClick={handlePasswordReset} type="submit">
        Reset password
      </IonButton>

      <IonLoading isOpen={loading} />
    </Page>
  );
};

export default Login;
