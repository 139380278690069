import { User, UserAction } from 'climate-call-up-typescript-interfaces';
import { convertTimestamps } from 'convert-firebase-timestamp';
import firebase from 'firebase';

import config from '../config.json';
import { analytics, firestore } from '../firebase';

interface AllUsersImpactPointsAPIResponse {
  data?: number;
  error?: Error;
}

interface NewUserInfo {
  country: string | undefined;
  email: string;
  firstName: string | undefined;
  id: string;
}

export async function getAllUsersImpactPoints(): Promise<number | null> {
  const madeUpTotal = 3255;

  if (window.location.origin !== config.siteUrl) return madeUpTotal;

  try {
    const response = await fetch(`${config.siteUrl}/api/impact-points/all`);
    const responseObject = (await response.json()) as AllUsersImpactPointsAPIResponse;

    if (responseObject.data) {
      return responseObject.data ?? null;
    } else {
      throw new Error('API did not return a result');
    }
  } catch (error) {
    console.error("Error getting all users' impact points", error);
    analytics?.logEvent('error', {
      errorLocation: 'users - getAllUsersImpactPoints',
      errorMessage: (error as Error).message ?? JSON.stringify(error),
    });

    return madeUpTotal;
  }
}

export async function getOrCreateUser(userInfo: NewUserInfo): Promise<User | undefined> {
  try {
    let user: User;

    const userRef = firestore.collection('users').doc(userInfo.id);
    const databaseUser = await userRef.get();

    if (databaseUser.exists) {
      user = databaseUser.data() as User;
    } else {
      user = {
        consent: {
          email: true,
        },
        profile: {
          country: userInfo.country,
          email: userInfo.email,
          firstName: userInfo.firstName,
        },
      };

      await firestore.collection('users').doc(userInfo.id).set(user);
    }

    try {
      const adminRef = firestore.collection('admins').doc(userInfo.id);
      const databaseAdmin = await adminRef.get();

      if (databaseAdmin.exists) {
        user.admin = true;
      }
    } catch (error) {
      // Not an admin
    }

    return user;
  } catch (error) {
    console.error('Error getting/creating user', error);
    analytics?.logEvent('error', {
      errorLocation: `users - ${userInfo.email} - getOrCreateUser`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return {};
  }
}

export async function getUserActions(id: string): Promise<UserAction[] | null> {
  try {
    const ref = firestore.collection('users').doc(id).collection('actions');
    const snapshot = await ref.get();

    if (snapshot.size) {
      const userActions = snapshot.docs.map((doc) => {
        let userAction = doc.data();
        userAction = convertTimestamps(userAction) as UserAction;

        return {
          id: doc.id,
          ...userAction,
        } as UserAction;
      });

      return userActions;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting user actions', error);
    analytics?.logEvent('error', {
      errorLocation: `users - ${id} - getUserActions`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return null;
  }
}

export async function getUserImpactPoints(id: string): Promise<number | null> {
  try {
    let user: User;
    const ref = firestore.collection('users').doc(id);
    const databaseUser = await ref.get();

    if (databaseUser.exists) {
      user = databaseUser.data() as User;

      return user.impactPoints ?? 0;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting user impact points', error);
    analytics?.logEvent('error', {
      errorLocation: `users - ${id} - getUserImpactPoints`,
      errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
    });

    return null;
  }
}
