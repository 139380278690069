import { IonButton, IonIcon, IonItem, IonList } from '@ionic/react';
import { add as addIcon } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Action as ActionInterface, UserAction } from 'climate-call-up-typescript-interfaces';

import { AuthContext, canEdit } from '../auth';
import Loading from '../components/Loading';
import Action from '../components/Action';
import '../css/pages/Actions.css';
import { blankAction } from '../data/actions';
import { firestore } from '../firebase';
import Page from './Page';

interface Props {
  loading: boolean;
  actions: ActionInterface[] | null;
  updateAction: (actionUpdated: ActionInterface, instruction: 'create' | 'delete' | 'update') => void;
  updateUserAction: (userActionUpdated: UserAction, instruction: 'create' | 'delete' | 'update') => void;
  userActions: UserAction[] | null;
}

const Actions: React.FC<Props> = (props: Props) => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);

    const action = blankAction();
    const response = await firestore.collection('actions').add({
      dateDisplayFormat: action.dateDisplayFormat,
      dateExpires: action.dateExpires,
      dateFrom: action.dateFrom,
      description: action.description,
      difficulty: action.difficulty,
      image: action.image,
      imageCredit: action.imageCredit,
      impactPoints: action.impactPoints,
      link: action.link,
      status: action.status,
      timeLimited: action.timeLimited,
      tips: action.tips,
      title: action.title,
      type: action.type,
    } as ActionInterface);

    const id = response.id;

    props.updateAction(
      {
        ...action,
        id,
      },
      'create',
    );

    setTimeout(() => {
      setLoading(false);
      history.push(`/actions/${id}/edit`);
    }, 2000);
  };

  return (
    <Page title="Actions">
      {props.loading || loading ? (
        <Loading />
      ) : (
        <div className="actions__list__container">
          {canEdit(auth) ? (
            <IonButton expand="block" onClick={handleCreate}>
              <IonIcon icon={addIcon} slot="start" />
              Add new action
            </IonButton>
          ) : (
            ''
          )}

          <IonList className="actions__list">
            {props.actions?.map((action) => {
              const userActions = props.userActions
                ? props.userActions.filter((userAction) => userAction.id === action.id)
                : [];

              return (
                <IonItem className="actions__list__item" key={action.id} lines="none">
                  <Action
                    display="list"
                    edit={false}
                    action={action}
                    updateAction={props.updateAction}
                    updateUserAction={props.updateUserAction}
                    userAction={userActions.length ? userActions[0] : null}
                  />
                </IonItem>
              );
            })}
          </IonList>
        </div>
      )}
    </Page>
  );
};

export default Actions;
