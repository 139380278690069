import { User } from 'climate-call-up-typescript-interfaces';
import firebase from 'firebase';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
  IonToast,
  IonToggle,
} from '@ionic/react';
import React, { useContext, useState } from 'react';

import { AuthContext } from '../auth';
import ContentColumn from '../components/ContentColumn';
import ImpactScore from '../components/ImpactScore';
import config from '../config.json';
import '../css/pages/Impact.css';
import { analytics, auth as firebaseAuth, firestore } from '../firebase';
import Page from './Page';

interface Props {
  impactPointsUser: number | null;
  impactPointsAllUsers: number | null;
}

const Impact: React.FC<Props> = (props: Props) => {
  const auth = useContext(AuthContext);
  const [consentEmail, setConsentEmail] = useState<boolean>(
    auth.user?.consent?.hasOwnProperty('email') ? Boolean(auth.user.consent.email) : true,
  );
  const [toast, setToast] = useState({ show: false, message: '' });

  const handleLogout = () => {
    firebaseAuth.signOut();
  };

  const handleUpdateConsent = async (consent: boolean): Promise<void> => {
    try {
      if (auth.userId) {
        await firestore
          .collection('users')
          .doc(auth.userId)
          .set(
            {
              consent: {
                email: consent,
              },
            } as User,
            { merge: true },
          );
      }
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Impact - ${auth?.userId} - handleUpdateConsent`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setToast({
        show: true,
        message: 'Error - sorry, we could not update your email consent',
      });
      setTimeout(() => setToast({ show: false, message: '' }), 3000);
    }
  };

  return (
    <Page title="Impact">
      <div className="impact__header">
        {auth.user?.profile?.firstName && (
          <IonText>
            <h2 className="impact__first-name">{auth.user.profile.firstName}</h2>
            <div className="text--centred">{auth.user?.profile?.email}</div>
          </IonText>
        )}
      </div>

      <div className="impact__ratings">
        <IonGrid className="content__grid">
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeSm="6">
              <div className="impact__total">
                {props.impactPointsUser !== null ? (
                  <ImpactScore caption="You" display="body" points={props.impactPointsUser} />
                ) : (
                  <IonSpinner />
                )}
              </div>
            </IonCol>
            <IonCol size="12" sizeSm="6">
              <div className="impact__total">
                {props.impactPointsAllUsers !== null ? (
                  <ImpactScore caption="Everyone" display="body" points={props.impactPointsAllUsers} />
                ) : (
                  <IonSpinner />
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <ContentColumn>
          <IonText className="important">
            <p>
              {config.siteName} wants to inspire and share the most impactful effective tactics to bring about actual
              change.
            </p>
            <p>
              Everyone should act, and act together, because it’s the only possible response to the colossal crisis we
              face. But if we could figure out the impact of tactics, it could help make them more effective, and help
              inspire and sustain action.
            </p>
            <p>
              This is an experimental feature. We&apos;ll use several measures to derive these ratings, including
              actions completed, difficulty and impact of each action, etc. Let’s see if it helps. But the main thing
              is: to act.
            </p>
          </IonText>
        </ContentColumn>
      </div>
      <div className="impact__profile">
        <IonText>
          <h3>Preferences</h3>
        </IonText>

        <ContentColumn>
          <IonItem className="impact__profile__item" lines="none">
            <IonLabel>Email me latest actions and news</IonLabel>
            <IonToggle
              checked={consentEmail}
              onIonChange={(event) => {
                setConsentEmail(event.detail.checked);
                handleUpdateConsent(event.detail.checked);
              }}
            />
          </IonItem>

          <IonButton className="impact__logout" color="medium" expand="block" onClick={handleLogout}>
            Logout
          </IonButton>
        </ContentColumn>
      </div>

      <IonToast isOpen={toast.show} message={toast.message}></IonToast>
    </Page>
  );
};

export default Impact;
