import firebase from 'firebase';
import { IonButton, IonInput, IonItem, IonLabel, IonLoading, IonText } from '@ionic/react';
import React, { useState } from 'react';

import AuthProviderButton from '../components/AuthProviderButton';
import ContentColumn from '../components/ContentColumn';
import ErrorMessage from '../components/ErrorMessage';
import OrDivider from '../components/OrDivider';
import '../css/pages/Login.css';
import { analytics, auth } from '../firebase';
import { useLocalStorage } from '../hooks';
import Page from './Page';
import { validateEmailAddress } from '../validators';

const Login: React.FC = () => {
  const [email, setEmail] = useLocalStorage('email', '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const errorTextDefault = 'Sorry, we could not log you in';
  const errorLinkDefault = {
    text: '',
    url: '',
  };
  const [error, setError] = useState({
    exists: false,
    preventSubmit: false,
    text: errorTextDefault,
    link: errorLinkDefault,
  });
  const url = new URL(window.location.href);
  const adminParam = url.searchParams.get('admin');

  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>): void => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError({ exists: false, preventSubmit: false, text: error.text, link: errorLinkDefault });

      analytics?.logEvent('login', { method: 'email' });

      await auth.signInWithEmailAndPassword(email, password);

      // TODO: Handle user not registered but trying to log in

      setLoading(false);
    } catch (error) {
      console.error('Login error', (error as firebase.auth.Error).code);
      analytics?.logEvent('error', {
        errorLocation: `Login - ${email} - handleLogin`,
        errorMessage: (error as firebase.auth.Error).message ?? JSON.stringify(error),
      });

      setLoading(false);

      let errorText;
      let errorLink = errorLinkDefault;
      switch ((error as firebase.auth.Error).code) {
        case 'auth/user-not-found':
          errorText = "Can't find your details - please check your email address or ";
          errorLink = {
            text: 'enlist as a new user here.',
            url: '/enlist',
          };
          break;
        case 'auth/wrong-password':
          errorText = 'Wrong password - please check and try again';
          break;
        default:
          errorText = errorTextDefault;
          break;
      }
      setError({ exists: true, preventSubmit: false, text: errorText, link: errorLink });
    }
  };

  const validateEmail = (): boolean => {
    const valid = validateEmailAddress(email);

    if (!valid) {
      setError({ exists: true, preventSubmit: true, text: 'Please check your email address', link: errorLinkDefault });
    } else {
      setError({ exists: false, preventSubmit: false, text: error.text, link: errorLinkDefault });
    }

    return valid;
  };

  const validateFieldsCompleted = (): boolean => Boolean(email) && Boolean(password);

  const validateForm = (): boolean => {
    return validateFieldsCompleted() && validateEmailAddress(email);
  };

  return (
    <Page title="Login">
      <ContentColumn>
        {adminParam && (
          <React.Fragment>
            <AuthProviderButton
              errorHandler={setError}
              mode="login"
              provider="facebook"
              providerReadableName="Facebook"
            />
            <AuthProviderButton errorHandler={setError} mode="login" provider="google" providerReadableName="Google" />

            <OrDivider />
          </React.Fragment>
        )}

        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            onIonBlur={validateEmail}
            onIonChange={(event) => setEmail(event.detail.value?.trim() ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            type="email"
            value={email}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            onIonChange={(event) => setPassword(event.detail.value?.trim() ?? '')}
            onKeyPress={(event) => handleKeyPress(event)}
            required
            type="password"
            value={password}
          />
        </IonItem>

        <ErrorMessage
          exists={error.exists}
          link={{ text: error.link.text, url: error.link.url }}
          preventSubmit={error.preventSubmit}
          text={error.text}
        />

        <IonButton
          className="login__button--submit"
          disabled={!validateFieldsCompleted() || error.preventSubmit}
          expand="block"
          onClick={handleLogin}
          type="submit"
        >
          Login
        </IonButton>

        <IonButton expand="block" fill="clear" routerLink="/forgot-password">
          Forgot password?
        </IonButton>

        <IonText>
          <hr />
          <p className="enlist__already">New here?</p>
        </IonText>

        <IonButton expand="block" fill="clear" routerLink="/enlist">
          Enlist
        </IonButton>
      </ContentColumn>

      <IonLoading isOpen={loading} />
    </Page>
  );
};

export default Login;
