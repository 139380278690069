import { getPlatforms, isPlatform } from '@ionic/react';

export const isDesktop = (): boolean => isWeb() && (isPlatform('desktop') || window.innerWidth > 992);

export const isWeb = (): boolean => {
  const webPlatforms = ['desktop', 'mobileweb', 'pwa'];
  const userPlatforms = getPlatforms();

  const matchingPlatforms = userPlatforms.filter((userPlatform) => webPlatforms.includes(userPlatform));

  return Boolean(matchingPlatforms.length);
};
