import { Browser } from '@capacitor/browser';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import { openOutline as openOutlineIcon } from 'ionicons/icons';
import React from 'react';

import ContentColumn from '../components/ContentColumn';
import LinkExternal from '../components/LinkExternal';
import config from '../config.json';
import '../css/pages/About.css';
import Page from './Page';

const About: React.FC = () => {
  const ww2Articles = [
    {
      headline: "We're under attack from climate change",
      link: 'https://newrepublic.com/article/135684/declare-war-climate-change-mobilize-wwii',
    },
    {
      headline: 'The climate crisis is our third world war',
      link: 'https://www.theguardian.com/commentisfree/2019/jun/04/climate-change-world-war-iii-green-new-deal',
    },
  ];

  return (
    <Page title="About">
      <ContentColumn>
        <IonText>
          <p>{config.siteName} helps you play your part in fixing the climate emergency.</p>
          <p>
            Individual action is great. But after decades of delay, we now need dramatic government-led action on our
            behalf.
          </p>
          <p>
            Just as in World War 2, we will only win through the immediate urgent mobilisation of all society’s
            resources. An all-out effort that reorients government policy, industry, and daily life, well before the
            2050 deadline, which is frankly way too late.
          </p>
          <p>And that won&apos;t come without your voice, whoever you are, whatever your journey.</p>
          <p>
            From the suffragettes to the civil rights movement, it&apos;s clearly established that governments respond
            to sustained non-violent direct pressure. exerted by citizens whose example convinces others.
          </p>
          <p>
            {config.siteName} wants to source the most innovative and effective actions, from the smartest campaigners
            all around the world, to help everyone step up and fix the climate emergency.
          </p>
          <p>
            Sign up or <LinkExternal to={config.donateUrl}>donate</LinkExternal> to play your part.
          </p>

          <h3 className="ion-margin-top ion-padding-top">Battles</h3>
          <p>
            There will be many battles on the way to climate recovery. These are the most urgent ones to push through:
          </p>

          <h4 className="about__battles__heading">1. Stop investing in fossil fuels</h4>
          <p>
            An immediate halt to new investments in fossil fuels, including government subsidies. Swiftly followed by a
            plan to phase them out.
          </p>
          <h4 className="about__battles__heading">2. Carbon Tax</h4>
          <p>
            A substantial levy on emissions from flights, petrol, meat, and other CO2-generating activities, with a
            rebate to taxpayers.
          </p>
          <h4 className="about__battles__heading">3. Wartime-level public investment in sustainable energy</h4>
          <p>
            Subsidise R&amp;D and public take-up of EVs, heat pumps, public transport, in developed and developing
            countries.
          </p>

          <h3 className="ion-margin-top ion-padding-top">Wartime-level mobilisation</h3>
          <p>Wartime-level mobilisation represents a fundamental step-change from our current approach.</p>
          <p>It means we have to do whatever it takes.</p>
          <p>It means involving and mobilising all the population, across all social, ethnic and class divides.</p>
          <p>
            It means marshalling all our resources, using extraordinary yet pragmatic measures in regulation, rationing,
            and taxation.
          </p>
          <p>It means embracing mandatory measures, not just voluntary incentives.</p>
          <p>It means an end to the appeasement of vested interests, in particular the fossil fuel lobby. </p>
          <p>
            It means a huge ongoing communications push, explaining what is at stake and bringing the public along with
            the effort.
          </p>
          <p>
            It means an honest and inclusive government, leading in good faith, exercising its powers without abuse, and
            sharing the burdens equally - as well as the rewards, such as green new jobs and investment.
          </p>
          <p>
            This level of collective mobilization runs deep in our collective memory and culture - despite its agonies
            and hardships, World War 2 is perceived as a time of pulling together against impossible odds. Ultimately
            leading to success.
          </p>
          <p>
            It&apos;s not just an intellectual reference point. Like any society in history, we need to marshall our
            experience and stories to help us overcome present dilemmas.
          </p>
          <p>We&apos;ve already drawn upon it to overcome Covid.</p>
          <p>
            The Climate Emergency is a still greater threat to our civilisation and world. Let&apos;s begin treating it
            as such.
          </p>
          <p>
            The urgency and scale of the threat in WWII unlocked huge resources and unthinkable policies. Here are a
            couple of powerful takes on how it applies to the climate emergency:
          </p>
          {ww2Articles.map((article) => (
            <IonItem
              className="about__article-link"
              detail
              detailIcon={openOutlineIcon}
              key={article.link}
              lines="none"
              onClick={async () => {
                await Browser.open({ url: article.link });
              }}
            >
              <IonLabel color="primary">{article.headline}</IonLabel>
            </IonItem>
          ))}

          <h3 className="ion-margin-top ion-padding-top">About us</h3>

          <p>
            {config.siteName} is a <LinkExternal to="https://www.utopiabureau.com/">Utopia Bureau</LinkExternal>{' '}
            project, working in collaboration with smart people and organisations worldwide. Get in touch if you want to
            collaborate.
          </p>

          <p>This is a first, beta version of {config.siteName}. Please let us know any feedback.</p>

          <p>You can reach us at hello [at] climatecallup.org</p>
        </IonText>
      </ContentColumn>
    </Page>
  );
};

export default About;
