import { PageContext } from './interfaces/PageContext';

interface Route {
  browserPageTitle?: string;
  path: string;
  title: string;
}

export function routesList(context?: PageContext): Route[] {
  return [
    {
      path: '/',
      title: '',
    },
    {
      path: '/about',
      title: 'About',
    },
    {
      path: '/actions',
      title: 'Actions',
    },
    {
      browserPageTitle: `Action detail${context?.action ? ` - ${context.action.title}` : ''}`,
      path: '/actions/:id',
      title: 'Action detail',
    },
    {
      browserPageTitle: `Edit action${context?.action ? ` - ${context.action.title}` : ''}`,
      path: '/actions/:id/edit',
      title: 'Edit action',
    },
    {
      path: '/alerts',
      title: 'Alerts',
    },
    {
      path: '/boris',
      title: 'Broadcast',
    },
    {
      path: '/enlist',
      title: 'Enlist',
    },
    {
      path: '/enlist/register',
      title: 'Enlist',
    },
    {
      path: '/enlist/success',
      title: 'You have enlisted!',
    },
    {
      path: '/forgot-password',
      title: 'Forgot password',
    },
    {
      path: '/forgot-password/reset',
      title: 'Reset your password',
    },
    {
      path: '/forgot-password/reset/complete',
      title: 'Password reset',
    },
    {
      path: '/forgot-password/sent',
      title: 'Email sent',
    },
    {
      path: '/home',
      title: '',
    },
    {
      path: '/hq',
      title: 'HQ',
    },
    {
      path: '/impact',
      title: 'Impact',
    },
    {
      path: '/launch',
      title: 'Launch',
    },
    {
      path: '/login',
      title: 'Login',
    },
    {
      path: '/privacy',
      title: 'Privacy policy',
    },
    {
      path: '/recruit',
      title: 'Recruit',
    },
    {
      path: '/terms',
      title: 'Terms & conditions',
    },
  ];
}
