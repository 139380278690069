import { Action as ActionInterface, NewsItem, UserAction } from 'climate-call-up-typescript-interfaces';
import {
  earth as earthIcon,
  enter as enterIcon,
  flame as flameIcon,
  flash as flashIcon,
  megaphone as megaphoneIcon,
  rocket as rocketIcon,
} from 'ionicons/icons';
import { IonApp, IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { ReactNode, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import screenfull from 'screenfull';

import { AuthContext, canEdit, useAuthInit } from './auth';
import CookieConsent from './components/CookieConsent';
import './css/App.css';
import { indexActions } from './data/actions';
import { getCO2Levels, getCO2LevelSource, getCO2LevelsLimits, indexNews } from './data/alerts';
import { updateItem } from './data/all';
import { getAllUsersImpactPoints, getUserImpactPoints, getUserActions } from './data/users';
import { analytics, app } from './firebase';
import { useLocalStorage } from './hooks';
import { CO2Levels as CO2LevelsInterface } from './interfaces/CO2Levels';
import About from './pages/About';
import Alerts from './pages/Alerts';
import Action from './pages/Action';
import Actions from './pages/Actions';
import Boris from './pages/Boris';
import Enlist from './pages/Enlist';
import EnlistRegister from './pages/EnlistRegister';
import EnlistSuccess from './pages/EnlistSuccess';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordReset from './pages/ForgotPasswordReset';
import ForgotPasswordResetComplete from './pages/ForgotPasswordResetComplete';
import ForgotPasswordSent from './pages/ForgotPasswordSent';
import HQ from './pages/HQ';
import Impact from './pages/Impact';
import Launch from './pages/Launch';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';
import Recruit from './pages/Recruit';
import Terms from './pages/Terms';
import { routesList } from './routes';
import { isDesktop, isWeb } from './utils/platforms';

const App: React.FC = () => {
  const [analyticsConsent, setAnalyticsConsent] = useState<boolean | undefined>(undefined);
  const [actions, setActions] = useState<ActionInterface[] | null>(null);
  const [co2Levels, setCO2Levels] = useState<CO2LevelsInterface | null>(null);
  const [co2LevelSource, setCO2LevelSource] = useState({ infoUrl: '', name: '' });
  const [country, setCountry] = useLocalStorage('country', '');
  const [firstName, setFirstName] = useLocalStorage('firstName', '');
  const [fullScreenRequested, setFullScreenRequested] = useState(false);
  const [impactPointsAllUsers, setImpactPointsAllUsers] = useState<number | null>(null);
  const [impactPointsUser, setImpactPointsUser] = useState<number | null>(null);
  const [loadingActions, setLoadingActions] = useState(false);
  const { loadingAuth, auth } = useAuthInit({
    country,
    firstName,
  });
  const [loadingUserActions, setLoadingUserActions] = useState(false);
  const [news, setNews] = useState<NewsItem[] | null>(null);
  const [userActions, setUserActions] = useState<UserAction[] | null>(null);

  const makeFullScreen = (): void => {
    if (!fullScreenRequested && !isDesktop() && screenfull.isEnabled) {
      setFullScreenRequested(true);
      screenfull.request(document.documentElement, { navigationUI: 'hide' }).catch(() => {
        console.error('Full screen error');
        analytics?.logEvent('error', {
          errorLocation: 'App - screenfull',
          errorMessage: 'Full screen error',
        });
      });
    }
  };

  const updateAction = (actionUpdated: ActionInterface, instruction: 'create' | 'delete' | 'update'): void => {
    const updatedActions = updateItem(actions, actionUpdated, instruction);
    setActions(updatedActions as ActionInterface[]);
  };

  const updateAnalyticsConsent = (value: boolean): void => {
    setAnalyticsConsent(value);
    localStorage.setItem('analyticsConsent', value ? 'true' : 'false');
    analytics?.setAnalyticsCollectionEnabled(value);
    if (value) app.analytics();
  };

  const updateImpactPoints = (): void => {
    if (auth?.userId) {
      getUserImpactPoints(auth.userId)
        .then((impactPoints) => setImpactPointsUser(impactPoints))
        .catch(() => console.error('getUserImpactPoints error'));
    }
    getAllUsersImpactPoints()
      .then((impactPoints) => setImpactPointsAllUsers(impactPoints))
      .catch(() => console.error('getAllUsersImpactPoints error'));
  };

  const updateNewsItem = (newsItemUpdated: NewsItem, instruction: 'create' | 'delete' | 'update'): void => {
    const updatedNews = updateItem(news, newsItemUpdated, instruction);
    setNews(updatedNews as NewsItem[]);
  };

  const updateUserAction = (userActionUpdated: UserAction, instruction: 'create' | 'delete' | 'update'): void => {
    const updatedUserActions = updateItem(userActions, userActionUpdated, instruction);
    setUserActions(updatedUserActions as UserAction[]);
  };

  useEffect(() => {
    if (localStorage.getItem('analyticsConsent') !== null) {
      setAnalyticsConsent(localStorage.getItem('analyticsConsent') === 'true' ? true : false);
    }

    getCO2Levels()
      .then((co2Levels) => {
        setCO2Levels(co2Levels);
        setCO2LevelSource(getCO2LevelSource());
      })
      .catch(() => console.error('getCO2Levels error'));

    indexNews()
      .then((news) => {
        setNews(news);
      })
      .catch(() => console.error('indexNews error'));

    getAllUsersImpactPoints()
      .then((impactPoints) => setImpactPointsAllUsers(impactPoints))
      .catch(() => console.error('getAllUsersImpactPoints error'));
  }, []);

  useEffect(() => {
    setLoadingActions(true);
    indexActions()
      .then((actions) => {
        setActions(canEdit(auth) ? actions : actions?.filter((action) => action.status === 'published') ?? null);
        setLoadingActions(false);
      })
      .catch(() => console.error('indexActions error'));

    if (auth?.userId) {
      setLoadingUserActions(true);
      getUserActions(auth.userId)
        .then((userActions) => {
          setUserActions(userActions);
          setLoadingUserActions(false);
        })
        .catch(() => console.error('getUserActions error'));

      getUserImpactPoints(auth.userId)
        .then((impactPoints) => setImpactPointsUser(impactPoints))
        .catch(() => console.error('getUserImpactPoints error'));
    }

    setFirstName(auth?.user?.profile?.firstName ?? firstName);
    setCountry(auth?.user?.profile?.country ?? country);
  }, [auth]);

  if (loadingAuth) {
    return <IonLoading isOpen message="Loading" spinner="lines" />;
  }

  return (
    <IonApp className="app">
      <AuthContext.Provider value={auth ?? { loggedIn: false }}>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              {routesList().map((route) => {
                let content: ReactNode;

                switch (route.path) {
                  case '/':
                    content = <Redirect to={auth?.loggedIn ? '/hq' : '/enlist'} />;
                    break;
                  case '/about':
                    content = <About />;
                    break;
                  case '/actions':
                    content = (
                      <Actions
                        loading={loadingActions || loadingUserActions}
                        actions={actions}
                        updateAction={updateAction}
                        updateUserAction={updateUserAction}
                        userActions={userActions}
                      />
                    );
                    break;
                  case '/actions/:id':
                    content = (
                      <Action
                        edit={false}
                        loading={loadingActions || loadingUserActions}
                        actions={actions}
                        updateAction={updateAction}
                        updateImpactPoints={updateImpactPoints}
                        updateUserAction={updateUserAction}
                        userActions={userActions}
                      />
                    );
                    break;
                  case '/actions/:id/edit':
                    content = (
                      <Action
                        edit
                        loading={loadingActions || loadingUserActions}
                        actions={actions}
                        updateAction={updateAction}
                        updateUserAction={updateUserAction}
                        userActions={userActions}
                      />
                    );
                    break;
                  case '/alerts':
                    content = (
                      <Alerts
                        co2Levels={co2Levels}
                        co2LevelSource={co2LevelSource}
                        co2LevelsLimits={getCO2LevelsLimits()}
                        loading={loadingActions || loadingUserActions}
                        news={news}
                        updateNewsItem={updateNewsItem}
                      />
                    );
                    break;
                  case '/boris':
                    content = <Boris />;
                    break;
                  case '/enlist':
                    content = auth?.loggedIn ? <Redirect to="/hq" /> : <Enlist />;
                    break;
                  case '/enlist/register':
                    content = auth?.loggedIn ? <Redirect to="/enlist/success" /> : <EnlistRegister />;
                    break;
                  case '/enlist/success':
                    content = <EnlistSuccess />;
                    break;
                  case '/forgot-password':
                    content = auth?.loggedIn ? <Redirect to="/impact" /> : <ForgotPassword />;
                    break;
                  case '/forgot-password/reset':
                    content = auth?.loggedIn ? <Redirect to="/impact" /> : <ForgotPasswordReset />;
                    break;
                  case '/forgot-password/reset/complete':
                    content = <ForgotPasswordResetComplete />;
                    break;
                  case '/forgot-password/sent':
                    content = <ForgotPasswordSent />;
                    break;
                  case '/home':
                    content = <Redirect to="/hq" />;
                    break;
                  case '/hq':
                    content = !auth?.loggedIn ? (
                      <Redirect to="/enlist" />
                    ) : (
                      <HQ
                        actions={actions}
                        co2Levels={co2Levels}
                        co2LevelsLimits={getCO2LevelsLimits()}
                        impactPointsUser={impactPointsUser}
                        impactPointsAllUsers={impactPointsAllUsers}
                        loading={loadingActions || loadingUserActions}
                        news={news}
                      />
                    );
                    break;
                  case '/impact':
                    content = auth?.loggedIn ? (
                      <Impact impactPointsUser={impactPointsUser} impactPointsAllUsers={impactPointsAllUsers} />
                    ) : (
                      <Redirect to="/login" />
                    );
                    break;
                  case '/launch':
                    content = <Launch />;
                    break;
                  case '/login':
                    content = auth?.loggedIn ? <Redirect to="/hq" /> : <Login />;
                    break;
                  case '/privacy':
                    content = <Privacy />;
                    break;
                  case '/recruit':
                    content = <Recruit />;
                    break;
                  case '/terms':
                    content = <Terms />;
                    break;
                }

                return (
                  <Route exact key={route.path} path={route.path}>
                    {content}
                  </Route>
                );
              })}

              <Route>
                <NotFound />
              </Route>
            </IonRouterOutlet>

            <IonTabBar color="primary" slot="bottom" onClick={makeFullScreen}>
              {auth?.loggedIn ? (
                <IonTabButton tab="hq" href="/hq" onClick={(event) => console.log(event)}>
                  <IonIcon icon={earthIcon} />
                  <IonLabel>HQ</IonLabel>
                </IonTabButton>
              ) : (
                <IonTabButton tab="enlist" href="/enlist">
                  <IonIcon icon={rocketIcon} />
                  <IonLabel>Enlist</IonLabel>
                </IonTabButton>
              )}
              <IonTabButton tab="alerts" href="/alerts">
                <IonIcon icon={megaphoneIcon} />
                <IonLabel>Alerts</IonLabel>
              </IonTabButton>
              <IonTabButton tab="actions" href="/actions">
                <IonIcon icon={flashIcon} />
                <IonLabel>Actions</IonLabel>
              </IonTabButton>
              {auth?.loggedIn ? (
                <IonTabButton tab="impact" href="/impact">
                  <IonIcon icon={flameIcon} />
                  <IonLabel>Impact</IonLabel>
                </IonTabButton>
              ) : (
                <IonTabButton tab="login" href="/login">
                  <IonIcon icon={enterIcon} />
                  <IonLabel>Login</IonLabel>
                </IonTabButton>
              )}
            </IonTabBar>
          </IonTabs>

          {isWeb() && analyticsConsent === undefined && (
            <CookieConsent updateAnalyticsConsent={updateAnalyticsConsent} />
          )}
        </IonReactRouter>
      </AuthContext.Provider>
    </IonApp>
  );
};

export default App;
