import { Action, NewsItem, UserAction } from 'climate-call-up-typescript-interfaces';

export function updateItem(
  allItems: Action[] | NewsItem[] | UserAction[] | null,
  itemUpdated: Action | NewsItem | UserAction,
  instruction: 'create' | 'delete' | 'update',
): Action[] | NewsItem[] | UserAction[] {
  if (!allItems) allItems = [];
  let allItemsUpdated;

  switch (instruction) {
    case 'create':
      allItemsUpdated = [itemUpdated, ...allItems];
      break;
    case 'delete':
      allItemsUpdated = [...allItems];
      allItemsUpdated.splice(
        allItemsUpdated.findIndex((itemExisting) => itemExisting.id === itemUpdated.id),
        1,
      );
      break;
    case 'update':
    default:
      allItemsUpdated = allItems.map((itemExisting: Action | NewsItem | UserAction) =>
        itemExisting.id === itemUpdated.id ? itemUpdated : itemExisting,
      );
  }

  return allItemsUpdated;
}
