import { IonText } from '@ionic/react';
import React from 'react';

import ContentColumn from '../components/ContentColumn';
import Share from '../components/Share';
import config from '../config.json';
import Page from './Page';

const Recruit: React.FC = () => {
  return (
    <Page title="Recruit">
      <ContentColumn>
        <IonText>
          <p>The climate emergency affects everyone. And everyone is needed to deal with it.</p>
          <p>Please share {config.siteName} with friends, family, colleagues. And take action together.</p>
        </IonText>
        <Share
          description="Step up! We face an existential emergency that requires a WW2-level
          mobilisation"
          dialogTitle="Recruit friends"
          hashtag={config.hashtag}
          title={config.siteName}
          url={config.siteUrl}
        />
      </ContentColumn>
    </Page>
  );
};

export default Recruit;
