import React from 'react';
import { IonSpinner, IonText } from '@ionic/react';

import '../css/components/Loading.css';

const Loading: React.FC = () => (
  <div className="loading">
    <IonSpinner className="loading__spinner" />
    <IonText>Loading</IonText>
  </div>
);

export default Loading;
