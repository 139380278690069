import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

import firebaseConfig from './firebase.config.json';

export const app = firebase.initializeApp(firebaseConfig);

const analyticsConsent = localStorage.getItem('analyticsConsent');
export const analytics = analyticsConsent === 'true' ? app.analytics() : null;

export const auth = app.auth();
export const authFacebook = new firebase.auth.FacebookAuthProvider();
export const authGoogle = new firebase.auth.GoogleAuthProvider();
export const firestore = app.firestore();
