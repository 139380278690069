import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import React from 'react';

import EnlistBegin from '../components/EnlistBegin';
import IntroBlurb from '../components/IntroBlurb';
import '../css/pages/Enlist.css';
import siren from '../images/siren-tall-800.png';
import sirenShort from '../images/siren-short-800.png';
import Page from './Page';

const Enlist: React.FC = () => {
  return (
    <Page title="Enlist!">
      <IonGrid>
        <IonRow>
          <IonCol className="enlist__siren__container" size="12" sizeSm="6" sizeMd="8" sizeLg="8" sizeXl="8">
            <IonImg alt="Air raid siren" className="enlist__siren ion-hide-sm-up" src={sirenShort} />
            <IonImg alt="Air raid siren" className="enlist__siren ion-hide-sm-down" src={siren} />
          </IonCol>
          <IonCol size="12" sizeSm="6" sizeMd="4" sizeLg="4" sizeXl="4">
            <EnlistBegin display="sidebar" />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IntroBlurb />
    </Page>
  );
};

export default Enlist;
