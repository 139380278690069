import { Action, NewsItem } from 'climate-call-up-typescript-interfaces';
import { arrowDown as arrowDownIcon, arrowForward as arrowForwardIcon, arrowUp as arrowUpIcon } from 'ionicons/icons';
import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonRow, IonSlide, IonSlides, IonSpinner } from '@ionic/react';
import React from 'react';
import CountUp from 'react-countup';
import Zoom from 'react-reveal/Zoom';

import CardImage from '../components/CardImage';
import ImpactScore from '../components/ImpactScore';
import IntroBlurb from '../components/IntroBlurb';
import '../css/pages/HQ.css';
import { CO2Levels } from '../interfaces/CO2Levels';
import { CO2LevelsLimits } from '../interfaces/CO2LevelsLimits';
import Page from './Page';

interface Props {
  actions: Action[] | null;
  co2Levels: CO2Levels | null;
  co2LevelsLimits: CO2LevelsLimits;
  impactPointsAllUsers: number | null;
  impactPointsUser: number | null;
  loading: boolean;
  news: NewsItem[] | null;
}

const HQ: React.FC<Props> = (props: Props) => {
  return (
    <Page className="hq" title="HQ">
      <IonGrid className="content__grid">
        <IonRow className="ion-justify-content-center">
          <IonCol className="hq__actions__col" size="12" sizeXl="8">
            {props.actions ? (
              <div className="hq__actions__action__slides__container">
                <Zoom>
                  <IonCard className="hq__actions__action hq__card">
                    <IonSlides
                      className="hq__actions__action__slides"
                      pager={true}
                      options={{
                        autoplay: {
                          delay: 6000,
                        },
                        keyboard: {
                          enabled: true,
                        },
                        loop: true,
                        navigation: true,
                        pagination: {
                          bulletClass: 'swiper-pagination-bullet',
                          bulletActiveClass: 'swiper-pagination-bullet-active',
                          clickable: true,
                          el: '.swiper-pagination',
                          type: 'bullets',
                        },
                        speed: 400,
                      }}
                    >
                      {props.actions?.slice(0, 3).map((action) => (
                        <IonSlide className="hq__actions__action__slides__slide" key={action.id}>
                          <IonCard className="hq__actions__action__card">
                            <CardImage
                              display="hq"
                              header={action.title}
                              routerLink={`/actions/${action.id}`}
                              url={action.image}
                            />
                          </IonCard>
                        </IonSlide>
                      ))}
                    </IonSlides>
                    <IonButton className="hq__card__button" expand="full" routerLink="/actions">
                      <IonIcon icon={arrowForwardIcon} slot="start" />
                      Actions
                    </IonButton>
                  </IonCard>
                </Zoom>
              </div>
            ) : (
              <IonCard className="hq__actions__action">
                <CardImage url="" />
              </IonCard>
            )}
          </IonCol>

          <IonCol className="hq__impact-alerts__col" size="12" sizeXl="4">
            <Zoom>
              <IonCard className="hq__card hq__impact" routerLink="/impact">
                <IonGrid className="hq__impact__content">
                  <IonRow className="ion-justify-content-center">
                    <IonCol className="hq__card__divider" size="12">
                      {props.impactPointsUser !== null ? (
                        <ImpactScore caption="You" display="sidebar" points={props.impactPointsUser} />
                      ) : (
                        <IonSpinner />
                      )}
                    </IonCol>
                    <IonCol className="hq__card__divider ion-hide-xxs-down" size="12">
                      {props.impactPointsAllUsers !== null ? (
                        <ImpactScore caption="Everyone" display="sidebar" points={props.impactPointsAllUsers} />
                      ) : (
                        <IonSpinner />
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButton className="hq__card__button hq__impact__button" expand="full" routerLink="/impact">
                  <IonIcon icon={arrowForwardIcon} slot="start" />
                  Impact
                </IonButton>
              </IonCard>
            </Zoom>

            <Zoom>
              <IonCard className="hq__alerts hq__card" routerLink="/alerts">
                <div className="hq__alerts__content">
                  <IonGrid>
                    <IonRow>
                      <IonCol className="hq__card__divider" size="12">
                        {props.co2Levels && props.co2Levels.latest ? (
                          <div className="hq__total">
                            <div className="hq__total__figure">
                              <CountUp
                                decimals={1}
                                delay={1}
                                duration={5}
                                end={parseFloat(props.co2Levels.latest.trend)}
                              />
                              <span>&nbsp;PPM</span>
                            </div>
                            <div className="hq__total__text">Latest CO2 level</div>
                          </div>
                        ) : (
                          <IonSpinner />
                        )}
                      </IonCol>
                      <IonCol className="hq__card__divider" size="12">
                        {props.co2Levels && props.co2Levels.latest && props.co2Levels.oneMonthAgo ? (
                          <div className="hq__total">
                            <div className="hq__total__figure">
                              <IonIcon
                                className="hq__total__figure__icon--co2-difference"
                                icon={
                                  parseFloat(props.co2Levels.latest.trend) -
                                    parseFloat(props.co2Levels.oneMonthAgo.trend) >=
                                  0
                                    ? arrowUpIcon
                                    : arrowDownIcon
                                }
                              />
                              <CountUp
                                decimals={2}
                                delay={1}
                                duration={5}
                                end={
                                  parseFloat(props.co2Levels.latest.trend) -
                                  parseFloat(props.co2Levels.oneMonthAgo.trend)
                                }
                              />
                              <span>&nbsp;PPM</span>
                            </div>
                            <div className="hq__total__text">in the past month</div>
                          </div>
                        ) : (
                          <IonSpinner />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <IonButton className="hq__card__button" expand="full" routerLink="/alerts">
                  <IonIcon icon={arrowForwardIcon} slot="start" />
                  Alerts
                </IonButton>
              </IonCard>
            </Zoom>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IntroBlurb />
    </Page>
  );
};

export default HQ;
