import React, { useContext, useEffect, useState } from 'react';

import { getOrCreateUser } from './data/users';
import { auth as firebaseAuth } from './firebase';
import { Auth } from './interfaces/Auth';
import { getFirstWord } from './utils/text';

interface AuthInit {
  loadingAuth: boolean;
  auth?: Auth;
}

interface UserExtraInfo {
  country?: string;
  firstName?: string;
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export const canEdit = (auth: Auth | undefined): boolean => {
  return Boolean(auth?.user?.admin);
};

export function useAuth(): Auth {
  return useContext(AuthContext);
}

export function useAuthInit(userExtraInfo?: UserExtraInfo): AuthInit {
  const [authInit, setAuthInit] = useState<AuthInit>({ loadingAuth: true });

  useEffect(() => {
    return firebaseAuth.onAuthStateChanged((firebaseUser) => {
      let auth;

      if (firebaseUser) {
        const firstName = firebaseUser.displayName ? getFirstWord(firebaseUser.displayName) : '';

        getOrCreateUser({
          country: userExtraInfo?.country ?? '',
          firstName: userExtraInfo?.firstName ? userExtraInfo?.firstName : firstName ? firstName : '',
          email: firebaseUser.email ?? '',
          id: firebaseUser.uid,
        })
          .then((user) => {
            auth = {
              loggedIn: true,
              user,
              userId: firebaseUser.uid,
            };

            setAuthInit({ loadingAuth: false, auth });
          })
          .catch(() => {
            auth = {
              loggedIn: true,
              userId: firebaseUser.uid,
            };

            setAuthInit({ loadingAuth: false, auth });
          });
      } else {
        auth = { loggedIn: false };

        setAuthInit({ loadingAuth: false, auth });
      }
    });
  }, []);

  return authInit;
}
