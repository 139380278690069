import { Action as ActionInterface, ActionTotal as ActionTotalInterface } from 'climate-call-up-typescript-interfaces';
import dayJS from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase/app';
import { decode } from 'html-entities';
import {
  accessibility as accessibilityIcon,
  arrowForward as arrowForwardIcon,
  bulbOutline as bulbOutlineIcon,
  chatbubbleOutline as chatbubbleOutlineIcon,
  checkmark as checkmarkIcon,
  create as createIcon,
  earth as earthIcon,
  eye as eyeIcon,
  flame as flameIcon,
  flash as flashIcon,
  mail as mailIcon,
  open as openIcon,
  phonePortraitOutline as phonePortraitOutlineIcon,
  refreshOutline as refreshOutlineIcon,
  rocketOutline as rocketOutlineIcon,
  shareOutline as shareIcon,
  star as starIcon,
  trash as trashIcon,
  arrowUndo as arrowUndoIcon,
} from 'ionicons/icons';
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonLoading,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonToast,
  IonToggle,
  useIonAlert,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link, useHistory } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import striptags from 'striptags';

import { AuthContext, canEdit } from '../auth';
import CardImage from './CardImage';
import config from '../config.json';
import '../css/components/Action.css';
import { getActionFeedback, getActionTotals } from '../data/actions';
import { analytics, firestore } from '../firebase';
import ContentColumn from './ContentColumn';
import { ActionComponent as ActionComponentInterface } from '../interfaces/ActionComponent';
import { ActionFeedback as ActionFeedbackInterface } from '../interfaces/ActionFeedback';
import Modal from './Modal';
import Share from './Share';
import EnlistBegin from './EnlistBegin';

dayJS.extend(relativeTime);
dayJS.extend(utc);

const Action: React.FC<ActionComponentInterface> = (props: ActionComponentInterface) => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const [inProgress, setInProgress] = useState({
    icon: '',
    message: '',
    show: false,
  });
  const [allFeedback, setAllFeedback] = useState<ActionFeedbackInterface[] | null>(null);
  const [descriptionHtml, setDescriptionHtml] = useState(props ? props.action?.description : '');
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAcceptanceModal, setShowAcceptanceModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [tipsHtml, setTipsHtml] = useState(props ? props.action?.tips : '');
  const [toast, setToast] = useState({ show: false, message: '' });
  const [totals, setTotals] = useState<ActionTotalInterface[] | null>([]);
  const [showAlert] = useIonAlert();

  const dateDisplayFormats = [
    { code: 'day', displayText: 'Day' },
    { code: 'month', displayText: 'Month' },
  ];
  const difficulties = [
    { code: 1, displayText: 'Easy' },
    { code: 2, displayText: 'Medium' },
    { code: 3, displayText: 'Hard' },
  ];
  const impactPoints = {
    acceptance: 5,
    max: 100,
    min: 10,
  };
  const statuses = [
    { code: 'draft', displayText: 'Draft' },
    { code: 'published', displayText: 'Published' },
  ];
  const types = [
    { code: 'inPerson', displayTextAdmin: 'In person', displayTextPublic: 'In person' },
    { code: 'onlineExternal', displayTextAdmin: 'Online - external website', displayTextPublic: 'Online' },
  ];

  const calculateNow = (): Date => new Date();

  const link = `/actions/${props.action.id}`;
  const share = {
    description: `Join me on this ${config.siteName} action!`,
    dialogTitle: 'Recruit friends',
    hashtag: config.hashtag,
    title: props.action.title,
  };

  useEffect(() => {
    if (props.action.id && canEdit(auth) && !allFeedback?.length) {
      getActionFeedback(props.action.id)
        .then((actionFeedback: ActionFeedbackInterface[] | null) => {
          setAllFeedback(actionFeedback);
        })
        .catch((error: Error) => {
          console.error('Error getting action feedback', error);
          analytics?.logEvent('error', {
            errorLocation: `Action - ${props.action.id} - get action feedback`,
            errorMessage: error.message ?? JSON.stringify(error),
          });
        });

      getActionTotals(props.action.id)
        .then((actionTotals: ActionTotalInterface[] | null) => {
          setTotals(actionTotals);
          console.log(actionTotals);
        })
        .catch((error: Error) => {
          console.error('Error getting action totals', error);
          analytics?.logEvent('error', {
            errorLocation: `Action - ${props.action.id} - get action totals`,
            errorMessage: error.message ?? JSON.stringify(error),
          });
        });
    }
  }, [props]);

  const cleanUpHtml = (html: string): string => {
    html = sanitizeHtml(html);
    html = html.replace('<a href', '<a target="_blank" rel="noreferrer" href');

    return html;
  };

  const dateDisplayFormatCode = (): string => {
    switch (props.action.dateDisplayFormat) {
      case 'month':
        return 'MMMM YYYY';
      case 'day':
      default:
        return 'D MMMM YYYY';
    }
  };

  const datesMatch = (date: 'day' | 'month' | 'year') => {
    let dateFormat: string;

    switch (date) {
      case 'day':
        dateFormat = 'DD';
        break;
      case 'month':
        dateFormat = 'MM';
        break;
      case 'year':
        dateFormat = 'YYYY';
        break;
    }

    return dayJS(props.action.dateFrom).format(dateFormat) === dayJS(props.action.dateExpires).format(dateFormat);
  };

  const isAccepted = (): boolean => {
    return props.userAction ? Boolean(props.userAction.accept) : false;
  };

  const isComplete = (): boolean => {
    return props.userAction ? Boolean(props.userAction.complete) : false;
  };

  const isEditMode = (): boolean => {
    return props.display === 'page' && props.edit && canEdit(auth);
  };

  const isFinished = (): boolean =>
    props.action.timeLimited && dayJS(props.action.dateExpires).diff(dayJS(), 'minute') <= 0;

  const isFinishingSoon = (): boolean =>
    props.action.timeLimited &&
    dayJS(props.action.dateExpires).diff(dayJS(), 'minute') > 0 &&
    dayJS(props.action.dateExpires).diff(dayJS(), 'day') < 14;

  const handleAcceptanceStatus = async (accept: boolean): Promise<void> => {
    try {
      setLoading(true);

      const acceptDate = calculateNow();

      await firestore.collection('users').doc(auth.userId).collection('actions').doc(props.action.id).set(
        {
          accept,
          acceptDate,
        },
        { merge: true },
      );

      await firestore
        .collection('users')
        .doc(auth.userId)
        .update({
          impactPoints: firebase.firestore.FieldValue.increment(
            accept ? impactPoints.acceptance : -impactPoints.acceptance,
          ),
        });

      if (props.updateImpactPoints) props.updateImpactPoints();

      props.updateUserAction(
        {
          ...props.userAction,
          id: props.action.id,
          accept,
          acceptDate,
        },
        props.userAction ? 'update' : 'create',
      );

      analytics?.logEvent(accept ? 'accept_action' : 'decline_action', {
        actionId: props.action.id,
        actionName: props.action.title,
      });

      setLoading(false);

      if (accept) {
        setInProgress({
          icon: starIcon,
          message: 'Signing you up to this action',
          show: true,
        });

        setTimeout(() => {
          setInProgress({
            icon: flameIcon,
            message: `Adding ${impactPoints.acceptance} impact points`,
            show: true,
          });
        }, 3000);

        setTimeout(() => {
          setInProgress({
            icon: bulbOutlineIcon,
            message: 'Accessing extra tips to help you succeed',
            show: true,
          });
        }, 6000);

        setTimeout(() => {
          setInProgress({
            icon: '',
            message: '',
            show: false,
          });
          setShowAcceptanceModal(true);
        }, 9000);
      } else {
        setToast({
          show: true,
          message: 'Action status updated',
        });

        setTimeout(() => {
          setToast({ show: false, message: '' });
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Action - ${props.action.id} - handle acceptance status`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setToast({
        show: true,
        message: 'Sorry, we ran into a problem - please try again',
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 3000);
    }
  };

  const handleCompletion = async (complete: boolean): Promise<void> => {
    try {
      setLoading(true);

      const completeDate = calculateNow();

      await firestore.collection('users').doc(auth.userId).collection('actions').doc(props.action.id).set(
        {
          complete,
          completeDate,
        },
        { merge: true },
      );

      const impactPointsCompletion = props.action.impactPoints - impactPoints.acceptance;

      await firestore
        .collection('users')
        .doc(auth.userId)
        .update({
          impactPoints: firebase.firestore.FieldValue.increment(
            complete ? impactPointsCompletion : -impactPointsCompletion,
          ),
        });

      if (props.updateImpactPoints) props.updateImpactPoints();

      setLoading(false);

      if (props.updateUserAction)
        props.updateUserAction(
          {
            ...props.userAction,
            id: props.action.id,
            complete,
            completeDate,
          },
          'update',
        );

      analytics?.logEvent(complete ? 'complete_action' : 'uncomplete_action', {
        actionId: props.action.id,
        actionName: props.action.title,
      });

      if (complete) {
        setInProgress({
          icon: rocketOutlineIcon,
          message: 'Saving your action success',
          show: true,
        });

        setTimeout(() => {
          setInProgress({
            icon: flameIcon,
            message: `Adding ${props.action.impactPoints - impactPoints.acceptance} impact points`,
            show: true,
          });
        }, 3000);

        setTimeout(() => {
          setInProgress({
            icon: earthIcon,
            message: 'Updating combined impact of entire community',
            show: true,
          });
        }, 6000);

        setTimeout(() => {
          setInProgress({
            icon: '',
            message: '',
            show: false,
          });
          setShowCompletionModal(true);
        }, 9000);
      } else {
        setShowCompletionModal(false);

        setLoading(false);
        setToast({
          show: true,
          message: 'Action status updated',
        });

        setTimeout(() => {
          setToast({ show: false, message: '' });
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Action - ${props.action.id} - handle completion`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setLoading(false);

      setToast({
        show: true,
        message: 'Sorry, we ran into a problem - please try again',
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 3000);
    }
  };

  const handleDelete = async (): Promise<void> => {
    try {
      setLoading(true);

      await firestore.collection('actions').doc(props.action.id).delete();

      setLoading(false);
      setToast({
        show: true,
        message: 'Action deleted',
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });

        props.updateAction(props.action, 'delete');

        history.push('/actions');
      }, 3000);
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Action - ${props.action.id} - handle delete`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setLoading(false);
      setToast({
        show: true,
        message: 'Error - could not delete action',
      });
      setTimeout(() => setToast({ show: false, message: '' }), 3000);
    }
  };

  const handleFeedback = async (): Promise<void> => {
    try {
      setLoading(true);

      await firestore.collection('actions').doc(props.action.id).collection('userFeedback').doc(auth.userId).set({
        message: feedback,
      });

      setShowFeedbackModal(false);
      setLoading(false);

      setToast({
        show: true,
        message: "Thanks for helping making these actions sharper. We'll take your feedback on board.",
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 3000);
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Action - ${props.action.id} - handle feedback`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setLoading(false);
      setToast({
        show: true,
        message: "Sorry - we ran into a problem and couldn't send your message",
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 3000);
    }
  };

  const handleLink = (url: string): boolean => {
    window.open(url, '_system', 'location,menubar,resizable,scrollbars,status,toolbar');

    return false;
  };

  const handleSave = async (): Promise<void> => {
    try {
      setLoading(true);

      await firestore
        .collection('actions')
        .doc(props.action.id)
        .set({
          dateDisplayFormat: props.action.dateDisplayFormat,
          dateExpires: props.action.dateExpires,
          dateFrom: props.action.dateFrom,
          description: descriptionHtml,
          difficulty: props.action.difficulty,
          image: props.action.image,
          imageCredit: props.action.imageCredit,
          impactPoints: props.action.impactPoints,
          link: props.action.link,
          status: props.action.status,
          timeLimited: props.action.timeLimited,
          tips: tipsHtml,
          title: props.action.title,
          type: props.action.type,
        } as ActionInterface);

      setLoading(false);
      setToast({
        show: true,
        message: 'Action changes saved',
      });

      setTimeout(() => {
        setToast({ show: false, message: '' });
      }, 3000);
    } catch (error) {
      console.error(error);
      analytics?.logEvent('error', {
        errorLocation: `Action - ${props.action.id} - handle save`,
        errorMessage: (error as firebase.firestore.FirestoreError).message ?? JSON.stringify(error),
      });

      setLoading(false);
      setToast({
        show: true,
        message: 'Error - could not save action',
      });
      setTimeout(() => setToast({ show: false, message: '' }), 3000);
    }
  };

  const handleUpdate = (action: ActionInterface): void => {
    props.updateAction(action, 'update');
  };

  const showExpireDate = (): boolean =>
    props.action.timeLimited &&
    (isFinishingSoon() || isFinished()) &&
    props.action.dateFrom !== props.action.dateExpires;

  return (
    <div>
      {isEditMode() && (
        <div>
          <h2>Edit action</h2>
          {props.action.title && <h3>{props.action.title}</h3>}
        </div>
      )}

      {isEditMode() && (
        <IonItemGroup>
          <IonItem>
            <IonLabel>Status</IonLabel>
            <IonSelect
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  status: event.detail.value,
                })
              }
              value={props.action.status ?? 'draft'}
            >
              {statuses.map((status) => (
                <IonSelectOption key={status.code} value={status.code}>
                  {status.displayText}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel>Type</IonLabel>
            <IonSelect
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  type: event.detail.value,
                })
              }
              value={props.action.type}
            >
              {types.map((type) => (
                <IonSelectOption key={type.code} value={type.code}>
                  {type.displayTextAdmin}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel>Difficulty</IonLabel>
            <IonSelect
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  difficulty: event.detail.value,
                })
              }
              value={props.action.difficulty}
            >
              {difficulties.map((difficulty) => (
                <IonSelectOption key={difficulty.code} value={difficulty.code}>
                  {difficulty.displayText}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel className="action__label--impact">Impact {props.action.impactPoints}</IonLabel>
            <IonRange
              color="secondary"
              max={impactPoints.max}
              min={impactPoints.min}
              onIonChange={(event) => {
                handleUpdate({
                  ...props.action,
                  impactPoints: event.detail.value as number,
                });
              }}
              snaps
              step={5}
              value={props.action.impactPoints}
            >
              <IonLabel className="action__impact-points__label" slot="start">
                {impactPoints.min}
              </IonLabel>
              <IonLabel className="action__impact-points__label" slot="end">
                {impactPoints.max}
              </IonLabel>
            </IonRange>
          </IonItem>

          <IonItem>
            <IonLabel>Date from</IonLabel>
            <IonDatetime
              displayFormat="DD MMMM YYYY H:mm"
              displayTimezone="utc"
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  dateFrom: new Date(event.detail.value ?? ''),
                })
              }
              value={props.action.dateFrom.toISOString()}
              yearValues="2021,2022,2023"
            />
          </IonItem>

          <IonItem>
            <IonLabel>Time limited?</IonLabel>
            <IonToggle
              checked={props.action.timeLimited}
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  timeLimited: event.detail.checked,
                })
              }
            />
          </IonItem>

          {props.action.timeLimited && (
            <IonItem>
              <IonLabel>Date expires</IonLabel>
              <IonDatetime
                displayFormat="DD MMMM YYYY H:mm"
                displayTimezone="utc"
                onIonChange={(event) =>
                  handleUpdate({
                    ...props.action,
                    dateExpires: new Date(event.detail.value ?? ''),
                  })
                }
                value={props.action.dateExpires.toISOString()}
                yearValues="2021,2022,2023"
              />
            </IonItem>
          )}

          <IonItem>
            <IonLabel>Display dates as</IonLabel>
            <IonSelect
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  dateDisplayFormat: event.detail.value,
                })
              }
              value={props.action.dateDisplayFormat}
            >
              {dateDisplayFormats.map((dateDisplayFormat) => (
                <IonSelectOption key={dateDisplayFormat.code} value={dateDisplayFormat.code}>
                  {dateDisplayFormat.displayText}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonItemGroup>
      )}

      <IonCard
        className={`card card--${props.display} action ${isFinished() && !isComplete() ? 'action--archived' : ''}`}
      >
        {props.display === 'list' ? (
          <CardImage
            className={isFinished() && !isComplete() ? 'action__image--archived' : ''}
            routerLink={link}
            url={props.action.image}
          />
        ) : (
          <Fade>
            <CardImage
              className={isFinished() && !isComplete() ? 'action__image--archived' : ''}
              credit={props.action.imageCredit}
              url={props.action.image}
            />
          </Fade>
        )}

        {isEditMode() ? (
          <IonItemGroup>
            <IonInput
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  image: event.detail.value ?? '',
                })
              }
              placeholder="Add an image URL"
              value={props.action.image}
            />
            <IonInput
              onIonChange={(event) =>
                handleUpdate({
                  ...props.action,
                  imageCredit: event.detail.value ?? '',
                })
              }
              placeholder="Add the image credit"
              value={props.action.imageCredit}
            />
          </IonItemGroup>
        ) : null}

        <IonCardHeader>
          <IonCardSubtitle className="subtitle">
            {canEdit(auth) && (
              <div className="subtitle__admin-warnings">
                {props.action.status === 'draft' && (
                  <IonBadge className="badge" color="danger">
                    Hidden until published
                  </IonBadge>
                )}
              </div>
            )}

            <div className="subtitle__info">
              <div className="subtitle__dates">
                {dayJS(props.action.dateFrom).format(
                  dateDisplayFormatCode()
                    .replace(' MMMM', showExpireDate() && datesMatch('month') && datesMatch('year') ? '' : ' MMMM')
                    .replace(' YYYY', showExpireDate() && datesMatch('year') ? '' : ' YYYY'),
                )}
                {showExpireDate() && <span> - {dayJS(props.action.dateExpires).format(dateDisplayFormatCode())}</span>}
              </div>

              {!isEditMode() && (
                <div className="subtitle__badge__container">
                  <IonBadge
                    className="badge"
                    color={
                      isComplete()
                        ? 'success'
                        : isFinished()
                        ? 'light'
                        : isFinishingSoon()
                        ? 'danger'
                        : isAccepted()
                        ? 'primary'
                        : 'tertiary'
                    }
                  >
                    {isComplete()
                      ? 'Action completed'
                      : isFinished()
                      ? 'Action archived'
                      : isFinishingSoon()
                      ? 'Ends soon'
                      : isAccepted()
                      ? 'Action accepted'
                      : 'New action'}
                  </IonBadge>
                  {props.display === 'page' && !isEditMode() && isAccepted() && !isComplete() && !isFinished() && (
                    <div className="button--skip" onClick={() => handleAcceptanceStatus(false)}>
                      Change
                    </div>
                  )}
                </div>
              )}
            </div>
          </IonCardSubtitle>
          <IonCardTitle className="action__title">
            {isEditMode() ? (
              <IonInput
                onIonChange={(event) =>
                  handleUpdate({
                    ...props.action,
                    title: event.detail.value ?? '',
                  })
                }
                placeholder="Add a title"
                value={props.action.title}
              />
            ) : props.display === 'list' ? (
              <h2>
                <Link
                  color={isFinished() && !isComplete() ? 'medium' : 'primary'}
                  className="actions__title__link"
                  to={link}
                >
                  {props.action.title}
                </Link>
              </h2>
            ) : (
              <Zoom>
                <h2>{props.action.title}</h2>
              </Zoom>
            )}
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent className="action__content">
          <ContentColumn
            classNameCol="action__content__content-column__column"
            classNameGrid="action__content__content-column"
          >
            {props.display === 'page' && (
              <IonGrid className="action__key-info__container">
                <IonRow>
                  <IonCol size="4">
                    <Zoom>
                      <div className="action__key-info">
                        <div className="action__key-info__icon-container">
                          <IonIcon
                            className="action__key-info__icon"
                            color="primary"
                            icon={props.action.type === 'inPerson' ? accessibilityIcon : phonePortraitOutlineIcon}
                            size="large"
                          />
                        </div>
                        <IonText className="action__key-info__text">
                          {types.filter((type) => type.code === props.action.type)[0].displayTextPublic}
                        </IonText>
                      </div>
                    </Zoom>
                  </IonCol>

                  <IonCol size="4">
                    <Zoom>
                      <div className="action__key-info">
                        <div className="action__key-info__icon-container">
                          {[1, 2, 3].map((difficultyLevel) => (
                            <IonIcon
                              className="action__key-info__icon"
                              color={difficultyLevel <= props.action.difficulty ? 'primary' : 'medium'}
                              icon={flashIcon}
                              key={difficultyLevel}
                              size="large"
                            />
                          ))}
                        </div>
                        <IonText className="action__key-info__text">Difficulty</IonText>
                      </div>
                    </Zoom>
                  </IonCol>

                  <IonCol size="4">
                    <Zoom>
                      <div className="action__key-info">
                        <div className="action__key-info__icon-container">
                          <IonIcon className="action__key-info__icon" color="primary" icon={flameIcon} size="large" />
                          <CountUp delay={2} duration={4} end={props.action.impactPoints} />
                        </div>
                        <IonText className="action__key-info__text">Impact</IonText>
                      </div>
                    </Zoom>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}

            {isEditMode() ? (
              <React.Fragment>
                <h5>Main description text</h5>
                <DefaultEditor
                  onChange={(event) => {
                    const element = event.target as HTMLInputElement;
                    const html = cleanUpHtml(element.value);

                    setDescriptionHtml(html);

                    handleUpdate({
                      ...props.action,
                      description: element.value,
                    });
                  }}
                  value={props?.action?.description ?? 'Description goes here'}
                />
              </React.Fragment>
            ) : (
              props.display === 'page' && (
                <Zoom>
                  <div
                    className="action__description"
                    dangerouslySetInnerHTML={{ __html: props.action.description }}
                  ></div>
                </Zoom>
              )
            )}

            {isEditMode() ? (
              <React.Fragment>
                <h5>Add extra tips (appear after action has been accepted)</h5>
                <DefaultEditor
                  onChange={(event) => {
                    const element = event.target as HTMLInputElement;
                    const html = cleanUpHtml(element.value);
                    setTipsHtml(html);
                    handleUpdate({
                      ...props.action,
                      tips: element.value,
                    });
                  }}
                  value={props?.action?.tips ?? ''}
                />
              </React.Fragment>
            ) : (
              props.display === 'page' &&
              Boolean(decode(striptags(props.action.tips)).trim().length) && (
                <Zoom>
                  <div className="action__tips">
                    <h4 className="action__tips__heading">Insider tips</h4>
                    {isAccepted() ? (
                      <div className="action__tips__text" dangerouslySetInnerHTML={{ __html: props.action.tips }}></div>
                    ) : (
                      <div className="action__tips__hint">
                        <IonIcon className="action__tips__hint__icon" color="medium" icon={eyeIcon} size="small" />
                        <span>Revealed when you accept the action</span>
                      </div>
                    )}
                  </div>
                </Zoom>
              )
            )}

            {props.display === 'page' && !isComplete() && !isFinished() && !isEditMode() && (
              <IonItemGroup className="acceptance-buttons">
                <Zoom>
                  {!isAccepted() && (
                    <IonButton
                      expand="block"
                      onClick={() => (auth.loggedIn ? handleAcceptanceStatus(true) : setShowAuthModal(true))}
                      strong
                    >
                      <IonIcon icon={starIcon} slot="start" />
                      Count me in!
                    </IonButton>
                  )}
                </Zoom>
              </IonItemGroup>
            )}

            <IonItemGroup className="action-buttons">
              {props.display === 'list' && (
                <IonButton
                  color={isFinished() && !isComplete() ? 'medium' : 'primary'}
                  expand="block"
                  routerLink={link}
                  strong
                >
                  <IonIcon icon={arrowForwardIcon} slot="start" />
                  Read more
                </IonButton>
              )}

              {props.display === 'page' && props.action.type === 'onlineExternal' ? (
                isEditMode() ? (
                  <IonInput
                    onIonChange={(event) =>
                      handleUpdate({
                        ...props.action,
                        link: event.detail.value ?? '',
                      })
                    }
                    placeholder="Add the link address"
                    value={props.action.link}
                  />
                ) : (
                  isAccepted() &&
                  !isFinished() && (
                    <Zoom>
                      <IonButton expand="block" onClick={() => handleLink(props.action.link ?? '')} strong>
                        <IonIcon icon={openIcon} slot="start" />
                        Take action
                      </IonButton>
                    </Zoom>
                  )
                )
              ) : null}

              {!isEditMode() && props.display === 'page' && isAccepted() && (
                <Zoom>
                  <IonButton
                    color={props.userAction?.complete ? 'success' : 'primary'}
                    expand="block"
                    onClick={() => {
                      if (props.userAction?.complete) {
                        setShowCompletionModal(true);
                      } else {
                        showAlert({
                          header: 'Completed this action?',
                          message: 'Have you fully completed this action? The earth is counting on you',
                          buttons: ['Not yet', { text: 'Yes I have', handler: () => handleCompletion(true) }],
                        });
                      }
                    }}
                    strong
                  >
                    <IonIcon icon={checkmarkIcon} slot="start" />
                    {props.userAction?.complete ? "I've completed the action" : 'Completed the action?'}
                  </IonButton>
                </Zoom>
              )}

              {!isEditMode() && props.display === 'page' && (
                <Fade>
                  <IonButton
                    className="button--share"
                    expand="block"
                    fill="clear"
                    onClick={() => setShowShareModal(true)}
                    size="small"
                  >
                    <IonIcon icon={shareIcon} slot="start" />
                    Share
                  </IonButton>
                </Fade>
              )}

              {!isEditMode() && props.display === 'page' && auth.loggedIn && !isFinished() && (
                <Fade>
                  <IonButton
                    className="button--feedback"
                    expand="block"
                    fill="clear"
                    onClick={() => setShowFeedbackModal(true)}
                    size="small"
                  >
                    <IonIcon icon={chatbubbleOutlineIcon} slot="start" />
                    Feedback
                  </IonButton>
                </Fade>
              )}
            </IonItemGroup>

            {canEdit(auth) && (props.display === 'list' || !isEditMode()) && (
              <IonItemGroup className="edit-button">
                <IonButton expand="block" fill="clear" routerLink={`${link}/edit`}>
                  <IonIcon icon={createIcon} slot="start" />
                  Edit
                </IonButton>
              </IonItemGroup>
            )}

            {isEditMode() && (
              <IonItemGroup className="update-buttons">
                <IonButton expand="block" onClick={handleSave}>
                  <IonIcon icon={checkmarkIcon} slot="start" />
                  Save
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  onClick={() =>
                    showAlert({
                      header: 'Delete this action?',
                      message: 'Are you sure you want to permanently delete this action?',
                      buttons: ['Cancel', { text: 'OK', handler: handleDelete }],
                    })
                  }
                >
                  <IonIcon icon={trashIcon} slot="start" />
                  Delete
                </IonButton>
              </IonItemGroup>
            )}
          </ContentColumn>
        </IonCardContent>
      </IonCard>

      {canEdit(auth) && props.display === 'page' && (
        <React.Fragment>
          <IonButton
            fill="clear"
            onClick={() => {
              location.reload();
              return false;
            }}
            size="small"
          >
            <IonIcon icon={refreshOutlineIcon} slot="start" />
            Refresh
          </IonButton>

          <IonText>
            <h4>User stats</h4>
            {totals?.map((total) => (
              <IonItem key={total.id}>
                Number of people {total.id} this action: {total.value}
              </IonItem>
            ))}

            <h4>User feedback</h4>
          </IonText>
          {allFeedback?.map((feedbackItem) => (
            <IonItem key={feedbackItem.userId}>{feedbackItem.message}</IonItem>
          ))}
        </React.Fragment>
      )}

      <IonLoading isOpen={loading} />

      <IonToast isOpen={toast.show} message={toast.message}></IonToast>

      <Modal cssClass="modal--auth" isOpen={showAuthModal} onClose={() => setShowAuthModal(false)}>
        <EnlistBegin display="modal" onSubmit={() => setShowAuthModal(false)} />
      </Modal>

      <Modal
        cssClass="modal--in-progress"
        hideCloseButton
        isOpen={inProgress.show}
        onClose={() => setInProgress({ icon: '', message: '', show: false })}
      >
        <div className="in-progress">
          <IonIcon className="in-progress__icon" color="secondary" icon={inProgress.icon} />
          <h3 className="in-progress__message">{inProgress.message}</h3>
        </div>
      </Modal>

      <Modal cssClass="modal--acceptance" isOpen={showAcceptanceModal} onClose={() => setShowAcceptanceModal(false)}>
        <div>
          <IonText className="action__modal__text action__modal__text--acceptance">
            <h2>Action in progress</h2>
            {auth.user?.consent?.email ? (
              <p>We&apos;ve emailed you the details.</p>
            ) : (
              <p>
                Enable emails at the bottom of <Link to="/impact">the Impact tab</Link> to get useful updates on this
                action.
              </p>
            )}
            <p>Let us know when you&apos;ve completed it!</p>
            <p>
              <strong>Now recruit friends to do it with you.</strong>
            </p>
          </IonText>
          <ContentColumn containerWidth="modal">
            <Share
              description={share.description}
              dialogTitle={share.dialogTitle}
              hashtag={share.hashtag}
              title={share.title}
              url={`${config.siteUrl}${link}`}
            />
          </ContentColumn>
        </div>
      </Modal>

      <Modal cssClass="modal--completion" isOpen={showCompletionModal} onClose={() => setShowCompletionModal(false)}>
        <IonText className="action__modal__text action__modal__text--completion">
          <h2>Action complete. Congratulations!</h2>
          <p>Any feedback for the next action?</p>
        </IonText>

        <ContentColumn containerWidth="modal">
          <IonButton expand="block" fill="solid" onClick={() => setShowFeedbackModal(true)}>
            <IonIcon icon={chatbubbleOutlineIcon} slot="start" />
            Feedback
          </IonButton>
        </ContentColumn>

        <IonText className="action__modal__text action__modal__text--completion">
          <p>Share this action and recruit others to do it.</p>
        </IonText>
        <ContentColumn containerWidth="modal">
          <Share
            description={share.description}
            dialogTitle={share.dialogTitle}
            hashtag={share.hashtag}
            title={share.title}
            url={`${config.siteUrl}${link}`}
          />
          <IonButton
            className="button--skip"
            color="secondary"
            expand="block"
            fill="clear"
            onClick={() => handleCompletion(false)}
          >
            <IonIcon icon={arrowUndoIcon} slot="start" /> Undo
          </IonButton>
        </ContentColumn>
      </Modal>

      <Modal cssClass="modal--feedback" isOpen={showFeedbackModal} onClose={() => setShowFeedbackModal(false)}>
        <div>
          <IonText className="action__modal__text action__modal__text--feedback">
            <h2>Feedback</h2>
            <p>Give us your feedback on this action, good or bad</p>
            <ContentColumn containerWidth="modal">
              <IonTextarea
                className="feedback__textarea"
                onIonChange={(event) => setFeedback(event.detail.value ?? '')}
                placeholder="Add your thoughts here"
                value={feedback}
              />
              <IonButton disabled={!feedback.length} expand="block" onClick={handleFeedback}>
                <IonIcon icon={mailIcon} slot="start" />
                Send
              </IonButton>
            </ContentColumn>
          </IonText>
        </div>
      </Modal>

      <Modal cssClass="modal--share" isOpen={showShareModal} onClose={() => setShowShareModal(false)}>
        <div>
          <IonText className="action__modal__text action__modal__text--share">
            <h2>Share this action</h2>
            <p>Recruit friends to do it with you.</p>
          </IonText>
          <ContentColumn containerWidth="modal">
            <Share
              description={share.description}
              dialogTitle={share.dialogTitle}
              hashtag={share.hashtag}
              title={share.title}
              url={`${config.siteUrl}${link}`}
            />
          </ContentColumn>
        </div>
      </Modal>
    </div>
  );
};

export default Action;
