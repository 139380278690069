import { shareOutline as shareIcon } from 'ionicons/icons';
import { IonButton, IonIcon, IonSpinner, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';

import ContentColumn from '../components/ContentColumn';
import config from '../config.json';
import '../css/pages/Launch.css';
import { getContent } from '../data/content';
import Modal from '../components/Modal';
import Share from '../components/Share';
import Page from './Page';

const Launch: React.FC = () => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const share = {
    description: config.siteDescription,
    dialogTitle: 'Recruit friends',
    hashtag: config.hashtag,
    title: config.siteName,
    url: config.siteUrl,
  };

  useEffect(() => {
    getContent('launch')
      .then((content) => {
        setContent(content?.html ?? '');
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Page title="Launch Campaign">
      <ContentColumn>
        <IonText>
          {loading ? (
            <IonSpinner />
          ) : (
            <div className="launch__content" dangerouslySetInnerHTML={{ __html: content }}></div>
          )}

          <div className="launch__video__container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/R0NZn3qPqC0?rel=0"
              title="Video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <p className="ion-margin-top ion-padding-top ion-text-center">
            Share this campaign with other humans you know
          </p>
        </IonText>
        <IonButton className="button--share" expand="block" fill="solid" onClick={() => setShowShareModal(true)}>
          <IonIcon icon={shareIcon} slot="start" />
          Share
        </IonButton>
      </ContentColumn>

      <Modal cssClass="modal--share" isOpen={showShareModal} onClose={() => setShowShareModal(false)}>
        <div>
          <IonText className="action__modal__text action__modal__text--share">
            <h2>Share</h2>
            <p>Recruit friends to join {config.siteName}</p>
          </IonText>
          <ContentColumn>
            <Share
              description={share.description}
              dialogTitle={share.dialogTitle}
              hashtag={share.hashtag}
              title={share.title}
              url={share.url}
            />
          </ContentColumn>
        </div>
      </Modal>
    </Page>
  );
};

export default Launch;
