import { Share as CapacitorShare } from '@capacitor/share';
import { arrowForward as arrowForwardIcon } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import config from '../config.json';
import '../css/components/Share.css';
import { isWeb } from '../utils/platforms';

interface Props {
  description: string;
  dialogTitle: string;
  hashtag: string;
  title: string;
  url: string;
}

const Share: React.FC<Props> = (props: Props) => {
  const iconSize = 48;
  const iconRound = true;

  return (
    <div className="share">
      {isWeb() ? (
        <div className="share__web">
          <div className="share__item">
            <EmailShareButton
              body={`${props.description}`}
              openShareDialogOnClick
              subject={props.title}
              url={props.url}
            >
              <EmailIcon size={iconSize} round={iconRound} />
            </EmailShareButton>
          </div>
          <div className="share__item">
            <WhatsappShareButton title={props.title} url={props.url}>
              <WhatsappIcon size={iconSize} round={iconRound} />
            </WhatsappShareButton>
          </div>
          <div className="share__item">
            <FacebookMessengerShareButton appId={config.facebookAppId} url={props.url}>
              <FacebookMessengerIcon size={iconSize} round={iconRound} />
            </FacebookMessengerShareButton>
          </div>
          <div className="share__item">
            <FacebookShareButton hashtag={props.hashtag} url={props.url}>
              <FacebookIcon size={iconSize} round={iconRound} />
            </FacebookShareButton>
          </div>
          <div className="share__item">
            <TwitterShareButton
              hashtags={[props.hashtag]}
              title={`${props.description} ${props.title}`}
              url={props.url}
            >
              <TwitterIcon size={iconSize} round={iconRound} />
            </TwitterShareButton>
          </div>
        </div>
      ) : (
        <IonButton
          expand="block"
          onClick={() =>
            CapacitorShare.share({
              title: props.title,
              text: props.description,
              url: props.url,
              dialogTitle: props.dialogTitle,
            })
          }
        >
          <IonIcon icon={arrowForwardIcon} slot="start" />
          Recruit friends
        </IonButton>
      )}
    </div>
  );
};

export default Share;
