import React from 'react';
import { IonText } from '@ionic/react';
import { Link } from 'react-router-dom';

import { ErrorMessage as ErrorMessageInterface } from '../interfaces/ErrorMessage';

const ErrorMessage: React.FC<ErrorMessageInterface> = (props: ErrorMessageInterface) => {
  return props.exists ? (
    <div className="ion-padding">
      <IonText color="danger">{props.text}</IonText>{' '}
      {props.link.text && <Link to={props.link.url}>{props.link.text}</Link>}
    </div>
  ) : null;
};

export default ErrorMessage;
